import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import {Svg} from "../../svg";
import {VehicleHeader} from "../../vehicle-header";
import {TransparentOffers} from "../transparent-offers";
import {CTAFooter} from "../../cta-footer";
import {DisclosureBlock} from "../../disclosure-block";

import copy from "./copy.json";
import {BrandingValueProp} from "../../branding";
import {warningSign} from "../../svg/colorful-concept-graphics";

import styles from "./offers-layout.module.scss"; // should be last import

export class OffersLayout extends React.Component {
    static propTypes = {
        // VehicleHeaderBlock configuration
        showVehicleHeaderBlock: PropTypes.bool,
        accessoriesAmount: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        vehicleConfiguration: PropTypes.shape({
            name: PropTypes.string.isRequired,
            msrp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            default_image: PropTypes.string,
        }),
        vssConfiguration: PropTypes.object,
        // DecisionBlock configuration
        showDecisionBlock: PropTypes.bool,
        decisionBlock: PropTypes.shape({
            headline: PropTypes.string.isRequired,
            body: PropTypes.string,
        }),

        showWarningNoteBlock: PropTypes.bool,
        warningNoteBlock: PropTypes.shape({ body: PropTypes.string }),

        // TransparentOffersBlock configuration
        showTransparentOffersBlock: PropTypes.bool,
        offers: PropTypes.arrayOf(
            PropTypes.shape({
                loan_amount: PropTypes.number.isRequired,
                term: PropTypes.number.isRequired,
                estimated_monthly_payment: PropTypes.number.isRequired,
                apr: PropTypes.number.isRequired,
                estimated_cash_down: PropTypes.number.isRequired,
                max_amount_financeable: PropTypes.number.isRequired,
            })
        ),

        // PaperworkBlock configuration
        showPaperworkBlock: PropTypes.bool,
        paperworkBlock: PropTypes.shape({
            body: PropTypes.string,
        }),

        // ContactBlock configuration
        showContactBlock: PropTypes.bool,
        contactInfo: PropTypes.shape({
            contact_name: PropTypes.string.isRequired,
            contact_address: PropTypes.arrayOf(PropTypes.string),
            contact_phone_number: PropTypes.string,
            contact_email_address: PropTypes.string,
        }),
        contactBlock: PropTypes.shape({
            headline: PropTypes.string.isRequired,
        }),

        // AdditionalBlock configuration
        showAdditionalBlock: PropTypes.bool,
        additionalBlock: PropTypes.shape({
            headline: PropTypes.string.isRequired,
            body: PropTypes.string,
        }),

        // DisclosureBlock configuration
        showDisclosureBlock: PropTypes.bool,
        disclosureBlock: PropTypes.shape({
            body: PropTypes.string.isRequired,
        }),

        // CtasBlock configuration
        showCTAsBlock: PropTypes.bool,
        ctasBlock: PropTypes.shape({
            isSticky: PropTypes.bool,
            primary: PropTypes.shape({
                oid: PropTypes.string,
                href: PropTypes.string,
                label: PropTypes.string.isRequired,

                isDisabled: PropTypes.bool,
                isExternalLink: PropTypes.bool,
                openLinkInNewTab: PropTypes.bool,

                onClick: PropTypes.func,
            }),
            secondary: PropTypes.oneOf([ 
                PropTypes.bool,
                PropTypes.shape({
                    oid: PropTypes.string,
                    href: PropTypes.string,
                    label: PropTypes.string.isRequired,
    
                    isDisabled: PropTypes.bool,
                    isExternalLink: PropTypes.bool,
                    openLinkInNewTab: PropTypes.bool,
    
                    onClick: PropTypes.func,
                }),
            ])
        }),
    };

    VehicleHeaderBlock = ()  => {
        const vehicleConfiguration = this.props.vehicleConfiguration || {};
        const accessoriesAmount = this.props.accessoriesAmount;

        return (
            <div className="w-500px mw-100 mx-auto">
                <VehicleHeader
                    vehicleConfiguration={{...vehicleConfiguration, accessoriesAmount}}
                    vssConfiguration={this.props.vssConfiguration}
                    partnerRawVehicleData={this.props.partnerRawVehicleData}
                    className={"px-3 py-4"}
                />
            </div>
        );
    };

    DecisionBlock = () => {
        const decisionBlock = this.props.decisionBlock || {};

        return (
            <div className="w-500px mw-100 mx-auto pt-4 pb-3">
                <div className="text-center">
                    <ReactMarkdown
                        source={decisionBlock.headline}
                        renderers={{
                            paragraph: ({ children }) => (
                                <p className="spark-text-success spark-text-montserrat spark-text-24px spark-text-italic">
                                    {children}
                                </p>
                            ),
                        }}
                    />
                    <ReactMarkdown className="mt-2">
                        {decisionBlock.subheading}
                    </ReactMarkdown>
                    <ReactMarkdown className="mt-2">
                        {decisionBlock.body}
                    </ReactMarkdown>
                </div>
            </div>
        );
    };

    WarningNoteBlock = () => {
        if (!this.props.warningNoteBlock || !this.props.warningNoteBlock.body) return null;
        const warningNoteBlockBody = this.props.warningNoteBlock.body;

        return (
            <div className="w-550px mw-100 mx-auto pb-4">
                <div className={`${styles.offersLayoutWarningNote} spark-text-14px`}>
                    {warningSign}
                    <p className={styles.offersLayoutWarningNoteText}> 
                        {warningNoteBlockBody} 
                    </p>
                </div>
            </div>
        );
    };

    TransparentOffersBlock = () => {
        const offers = this.props.offers || [];

        return (
            <div className="w-705px mw-100 mx-auto pt-1 pb-4">
                <TransparentOffers
                    hideSelectCTAs
                    offers={offers}
                />
            </div>
        );
    };

    PaperworkBlock = () => {
        const paperworkBlock = this.props.paperworkBlock || {};

        return (
            <div className="w-500px mw-100 mx-auto py-4 border-top">
                <div className="text-center">
                    <Svg svg="time-saving" className="spark-text-primary" style={{height: 40}}/>
                    <p className="my-3 spark-text-24px-bold">
                        {copy.paperwork_block.headline}
                    </p>
                    <p className="spark-text-16px">
                        {paperworkBlock.body ? paperworkBlock.body : copy.paperwork_block.body}
                    </p>
                </div>
            </div>
        );
    };

    ContactBlock = () => {
        const contactInfo = this.props.contactInfo || {};
        const contactBlock = this.props.contactBlock || {};

        return (
            <div className="w-500px mw-100 mx-auto py-4 border-top">
                <div className="text-center">
                    <p className="mb-3 spark-text-24px-bold">
                        {contactBlock.headline}
                    </p>
                    {!!contactInfo.contact_name && <p>{contactInfo.contact_name}</p>}
                    {!!contactInfo.contact_phone_number && <p>{contactInfo.contact_phone_number}</p>}
                    {!!contactInfo.contact_email_address && <p>{contactInfo.contact_email_address}</p>}
                    {!!contactInfo.contact_address && (
                        contactInfo.contact_address.map((line, i) => <p key={i}>{line}</p>)
                    )}
                </div>
            </div>
        );
    };

    AdditionalBlock = () => {
        const additionalBlock = this.props.additionalBlock || {};

        return (
            <div className="w-500px mw-100 mx-auto py-4 border-top">
                <div className="text-center">
                    <p className="mb-3 spark-text-24px-bold">
                        {additionalBlock.headline}
                    </p>
                    <ReactMarkdown>
                        {additionalBlock.body}
                    </ReactMarkdown>
                </div>
            </div>
        );
    };

    DisclosureBlock = () => {
        const disclosureBlock = this.props.disclosureBlock || {};
        return (
            <div className="w-500px mw-100 mx-auto">
                <DisclosureBlock
                    disclosureText={disclosureBlock.body}
                    showRuleLine
                />
            </div>
        );
    };

    CtasBlock = () => {
        const ctasBlock = this.props.ctasBlock || {};
        if (typeof ctasBlock.isSticky !== 'boolean') {
            ctasBlock.isSticky = true;
        }

        return (
            <CTAFooter
                isSticky={(ctasBlock.isSticky)}
                primary={ctasBlock.primary}
                secondary={ctasBlock.secondary}
            />
        );
    };

    render() {
        return (
            <div>
                {!!this.props.showVehicleHeaderBlock && <this.VehicleHeaderBlock/>}
                {!!this.props.showDecisionBlock && <this.DecisionBlock/>}
                {!!this.props.showWarningNoteBlock && <this.WarningNoteBlock/>}
                {!!this.props.showTransparentOffersBlock && <this.TransparentOffersBlock/>}
                {!!this.props.showPaperworkBlock && <this.PaperworkBlock/>}
                {!!this.props.showContactBlock && <this.ContactBlock/>}
                {!!this.props.showAdditionalBlock && <this.AdditionalBlock/>}
                {!!this.props.showDisclosureBlock && <this.DisclosureBlock/>}
                {!!this.props.showBrandingForCampingWorld &&
                <BrandingValueProp
                    showPoweredByOctane
                    showDesktopLayout
                    showBrandingForCampingWorld
                />}
                {!!this.props.showCTAsBlock && <this.CtasBlock/>}
            </div>
        );
    }
}
