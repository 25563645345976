import React from "react";

export const warningSign = (
    <svg 
        width="30"
        height="28"
        viewBox="0 0 30 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M7.52854 27H24.471C27.9943 27 30.2031 23.1941 28.4551 20.1353L19.9838 5.31186C18.2223 2.22938 13.7773 2.22938 12.0157 5.31186L3.54447 20.1353C1.79644 23.1941 4.00526 27 7.52854 27Z" fill="url(#paint0_linear_994_10)"/>
        <path d="M24.4708 23.7612H7.52836C6.4921 23.7612 5.84244 22.6418 6.35657 21.7422L14.8278 6.91873C15.3459 6.01212 16.6533 6.01212 17.1714 6.91873L25.6426 21.7422C26.1567 22.6418 25.5071 23.7612 24.4708 23.7612Z" fill="url(#paint1_linear_994_10)"/>
        <g filter="url(#filter0_d_994_10)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.4712 25.3808H7.52869C5.24893 25.3808 3.81969 22.9182 4.95077 20.939L13.422 6.11554L14.8281 6.91898L6.35691 21.7424C5.84278 22.6421 6.49244 23.7614 7.52869 23.7614H24.4712C25.5074 23.7614 26.1571 22.6421 25.6429 21.7424L17.1717 6.91898C16.6536 6.01237 15.3462 6.01237 14.8281 6.91898L13.422 6.11554C14.5618 4.12099 17.438 4.12099 18.5779 6.11554L27.0491 20.939C28.1802 22.9182 26.7509 25.3808 24.4712 25.3808Z" fill="url(#paint2_linear_994_10)"/>
        </g>
        <g filter="url(#filter1_i_994_10)">
            <path d="M15.9996 10.8062C16.4468 10.8062 16.8094 11.1687 16.8094 11.6159V17.5536C16.8094 18.0008 16.4468 18.3633 15.9996 18.3633C15.5524 18.3633 15.1898 18.0008 15.1898 17.5536V11.6159C15.1898 11.1687 15.5524 10.8062 15.9996 10.8062Z" fill="url(#paint3_linear_994_10)"/>
            <path d="M15.9996 21.3324C16.5959 21.3324 17.0793 20.8491 17.0793 20.2528C17.0793 19.6565 16.5959 19.1731 15.9996 19.1731C15.4033 19.1731 14.9199 19.6565 14.9199 20.2528C14.9199 20.8491 15.4033 21.3324 15.9996 21.3324Z" fill="url(#paint4_linear_994_10)"/>
        </g>
        <defs>
            <filter id="filter0_d_994_10" x="0.555176" y="0.619629" width="28.8896" height="26.7612" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="-1" dy="-1"/>
                <feGaussianBlur stdDeviation="1.5"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_994_10"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_994_10" result="shape"/>
            </filter>
            <filter id="filter1_i_994_10" x="13.9199" y="9.80615" width="3.15918" height="11.5264" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="-1" dy="-1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
                <feBlend mode="multiply" in2="shape" result="effect1_innerShadow_994_10"/>
            </filter>
            <linearGradient id="paint0_linear_994_10" x1="2.93311" y1="27" x2="31.891" y2="22.8871" gradientUnits="userSpaceOnUse">
                <stop stop-color="#D97D0E"/>
                <stop offset="1" stop-color="#FFE352"/>
            </linearGradient>
            <linearGradient id="paint1_linear_994_10" x1="6.17676" y1="23.7612" x2="27.9199" y2="20.5815" gradientUnits="userSpaceOnUse">
                <stop stop-color="#D97D0E"/>
                <stop offset="1" stop-color="#FFE352"/>
            </linearGradient>
            <linearGradient id="paint2_linear_994_10" x1="4.55518" y1="25.3808" x2="29.906" y2="21.7352" gradientUnits="userSpaceOnUse">
                <stop stop-color="#C65533"/>
                <stop offset="1" stop-color="#FDB814"/>
            </linearGradient>
            <linearGradient id="paint3_linear_994_10" x1="14.9199" y1="21.3324" x2="17.4414" y2="21.245" gradientUnits="userSpaceOnUse">
                <stop stop-color="#30333A"/>
                <stop offset="1" stop-color="#565B66"/>
            </linearGradient>
            <linearGradient id="paint4_linear_994_10" x1="14.9199" y1="21.3324" x2="17.4414" y2="21.245" gradientUnits="userSpaceOnUse">
                <stop stop-color="#30333A"/>
                <stop offset="1" stop-color="#565B66"/>
            </linearGradient>
        </defs>
    </svg>
);
