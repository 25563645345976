import React from 'react';
import PropTypes from "prop-types";

import copy from "../copy";
import {CONTACT_PREFERENCES} from "../../enums/contactPreferences";
import {ButtonSelectField} from "../../components/button-select-field";

/**
 * Reusable component for rendering contact preference form field
 *
 * @param {string}                 value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 controlId - input control id
 * @param {object}                 partnerStore - partner store object
 */
export function ContactPreferenceField({onChange, partnerStore,...props}) {
    const contactPreferenceItems = getContactPreferenceItems(partnerStore);

    // If there's only one contact preference, select it by triggering on change
    if(!props.value && contactPreferenceItems.length === 1){
        onChange({value: contactPreferenceItems[0].key, error: false});
    }

    const handleChange = (event) => {
        onChange({value: event.key, error: false});
    }

    const showField = !!contactPreferenceItems && contactPreferenceItems.length>1;

    return showField && (
        <ButtonSelectField
            {...copy.contact_preference}
            selected={props.value}
            items={contactPreferenceItems}
            onChange={handleChange}
            {...props}
        />
    );
}

ContactPreferenceField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string]),
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    controlId: PropTypes.string,
};

const getContactPreferenceItems = (partnerStore) => {
    const defaultOptions = Object.keys(CONTACT_PREFERENCES);
    let validPartnerOptions = [];

    // get contact preferences from partner (when available), and filter out any invalid values
    if(partnerStore && partnerStore.partner && partnerStore.partner.contact_preference_options){
        const partnerOptions = partnerStore.partner.contact_preference_options;
        validPartnerOptions = (partnerOptions).filter((option) => defaultOptions.includes(option));
    }

    const options = !!validPartnerOptions && validPartnerOptions.length > 0
            ? validPartnerOptions
            : defaultOptions;

    return options.map(key => ({'key': key, 'label': CONTACT_PREFERENCES[key]}))
}
