import {action, decorate, observable} from "mobx";

export class DateInputStore {
    static PATTERN_LIST = [
        {isEditable: true, placeholder: "_"},
        {isEditable: true, placeholder: "_"},
        {isEditable: false, placeholder: " "},
        {isEditable: false, placeholder: "/"},
        {isEditable: false, placeholder: " "},
        {isEditable: true, placeholder: "_"},
        {isEditable: true, placeholder: "_"},
        {isEditable: false, placeholder: " "},
        {isEditable: false, placeholder: "/"},
        {isEditable: false, placeholder: " "},
        {isEditable: true, placeholder: "_"},
        {isEditable: true, placeholder: "_"},
        {isEditable: true, placeholder: "_"},
        {isEditable: true, placeholder: "_"}
    ];
    static AMOUNT_OF_EDITABLES = DateInputStore.PATTERN_LIST.filter(({isEditable}) => isEditable).length;
    static FULL_PATTERN = /^([01][0-9]) \/ ([0-3][0-9]) \/ ([12][0-9]{3})$/;
    static NON_CLEAN_CHARS = /[^0-9_]/g;

    constructor(value) {
        this.updateValue(value);
    }

    updateValue(newValue) {
        if (!newValue) {
            this.value = "";
            return this;
        }
        this.value = (typeof newValue === "object") ?
            this.formatToMatchPattern(
                `
                    ${newValue.month.toString().padStart(2, 0)} / 
                    ${newValue.day.toString().padStart(2, 0)} / 
                    ${newValue.year}
                `
            ) : this.value = newValue;
        return this;
    }
    
    /**
     * Determine if a value matches the FULL_PATTERN for a date and returns a date object if so
     * @param {string} value
     * @returns {object|boolean}
     *  {month:{number}, day:{number}, year:{number}}
     */
    getDate = (value = this.value) => {
        if (this.constructor.FULL_PATTERN.test(value)) {
            const {1: month, 2: day, 3: year} = this.constructor.FULL_PATTERN.exec(value);
            return {
                month: parseInt(month),
                day: parseInt(day),
                year: parseInt(year)
            };
        }
        return false;
    };
    
    /**
     * Format the given input and return a formatted value and the next index that the cursor should be at
     * @param {string} previousValue
     * @param {string} currentValue
     * @param {number} currentIndex
     * @param {number} previousIndex
     * @returns {object} {formatted: {string}, nextIndex: {number}}
     */
    formatInput = ({
        currentValue,
        previousValue,
        currentIndex,
        previousIndex
    }) => {
        let nextIndex = this.getNextEditableIndex(currentIndex);
        let formatted = this.formatToMatchPattern(currentValue);
    
        /* If something was deleted, or entire value was replaced/pasted over, placeholders must be inserted */
        if (currentValue.length < previousValue.length) {
            let insertPlaceholder = {
                index: currentIndex,
                amountToInsert: previousValue.length - currentValue.length
            };
            formatted = this.formatToMatchPattern(currentValue, insertPlaceholder);
    
            if (this.didBackspaceToAfterUneditable(currentIndex, previousIndex)) {
                let prevEditable = this.getNextEditableIndex(currentIndex - 1, false);
                nextIndex = prevEditable + 1;
            }
        } else if (this.isOverwritingNeeded(formatted, currentValue, previousValue)) {
            let [overwrittenValue, index] = this.overwriteChars({
                currentIndex,
                previousIndex,
                currentValue,
                previousValue
            });
            formatted = this.formatToMatchPattern(overwrittenValue);
    
            // If a character overwrote the same character, the index needs to move
            if (formatted === previousValue) {
                nextIndex = this.getNextEditableIndex(nextIndex);
            } else {
                nextIndex = index;
            }
        } else if (formatted === previousValue) {
            nextIndex = previousIndex;
        }
    
        return {formatted, nextIndex};
    };
    
    /**
     * Determines if the given indicies indicate that a user backspaced to after an uneditable
     * @param {number} currentIndex
     * @param {number} previousIndex
     * @returns {boolean}
     */
    didBackspaceToAfterUneditable = (currentIndex, previousIndex) => {
        return (
            currentIndex < previousIndex &&
            currentIndex < this.constructor.PATTERN_LIST.length &&
            currentIndex > 0 &&
            this.constructor.PATTERN_LIST[currentIndex - 1].isEditable === false
        );
    };
    
    /**
     * Determines if a character was inserted into the pattern and needs to overwrite another
     * @param {string} formatted
     * @param {string} currentValue
     * @param {string} previousValue
     * @returns {boolean}
     */
    isOverwritingNeeded = (formatted, currentValue, previousValue) => {
        return (
            previousValue.length === this.constructor.PATTERN_LIST.length &&
            currentValue.length > previousValue.length &&
            formatted !== previousValue
        );
    };
    
    /**
     * Using the given indices, find which part of the string should be overwritten, remove the appropiate characters and return the new string and the index the overwrote ended at
     * @param {string} previousValue
     * @param {string} currentValue
     * @param {number} currentIndex
     * @param {number} previousIndex
     * @returns {[string, index]}
     */
    overwriteChars = ({
        previousValue,
        currentValue,
        currentIndex,
        previousIndex
    }) => {
        let index = currentIndex;
        const diff = currentValue.length - previousValue.length;
    
        /* If the index is currently/previously not at an editable, the inserted characters were before or at an uneditable, shift the index so we're at the next editable index */
        if (
            previousIndex < this.constructor.PATTERN_LIST.length &&
            !this.constructor.PATTERN_LIST[previousIndex].isEditable
        ) {
            index = this.getNextEditableIndex(currentIndex) + diff;
        }
    
        let values = currentValue.split("");
        for (let i = index; i < index + diff; i++) {
            values[i] = "";
        }
        currentValue = values.join("");
    
        return [currentValue, index];
    };
    
    /**
     * Format a given string to match the PATTERN_LIST format and inserts placeholders if needed
     * @param {string} value
     * @param {object} insertPlaceholder to be used to insert "amountToInsert" placeholder characters at given "index"
     *  {index: {number}, amountToInsert: {number}}
     * @returns {string}
     */
    formatToMatchPattern = (value, insertPlaceholder = null) => {
        let formatted = "";
        let cleanValue = "";
        let cleanValueIndex = 0;
    
        cleanValue = value.replace(this.constructor.NON_CLEAN_CHARS, "");
    
        this.constructor.PATTERN_LIST.forEach(({isEditable, placeholder}, index) => {
            // If there are placeholders to insert here (because something was deleted)
            if (
                cleanValue.length < this.constructor.AMOUNT_OF_EDITABLES &&
                insertPlaceholder &&
                insertPlaceholder.index === index &&
                insertPlaceholder.amountToInsert > 0
            ) {
                formatted += placeholder;
                insertPlaceholder.index++;
                insertPlaceholder.amountToInsert--;
            }
            // Else, add either the next cleanValue or placeholder
            else {
                if (isEditable && cleanValue[cleanValueIndex]) {
                    formatted += cleanValue[cleanValueIndex];
                    cleanValueIndex++;
                } else {
                    formatted += placeholder;
                }
            }
        });
    
        return formatted;
    };
    
    /**
     * Return the next editable index including the given index if it is editable
     * @param {number} index
     * @param {boolean} getForwardIndex direction to start looking in
     * @returns {number}
     */
    getNextEditableIndex = (index, getForwardIndex = true) => {
        if (index < this.constructor.PATTERN_LIST.length && this.constructor.PATTERN_LIST[index].isEditable) {
            return index;
        } else {
            // If we are looking for indices after the current first
            if (getForwardIndex) {
                let nextEditable = this.findEditableAfterIndex(index);
                if (nextEditable) return nextEditable;
            }
            // If we are looking for indices before the current first
            else {
                let prevEditable = this.findEditableBeforeIndex(index);
                if (prevEditable) return prevEditable;
            }
        }
    
        return this.constructor.PATTERN_LIST.length;
    };
    
    /**
     * Find the first editable index from current index to end of PATTERN_LIST length
     * @param {number} index
     * @returns {number|boolean}
     */
    findEditableAfterIndex = (index) => {
        for (let i = index; i < this.constructor.PATTERN_LIST.length; i++) {
            if (i > 0 && this.constructor.PATTERN_LIST[i].isEditable) return i;
        }
        return false;
    };
    
    /**
     * Find the first editable index from current index to beginning of PATTERN_LIST
     * @param {number} index
     * @returns {number|boolean}
     */
    findEditableBeforeIndex = (index) => {
        for (let i = index; i >= 0; i--) {
            if (i < this.constructor.PATTERN_LIST.length && this.constructor.PATTERN_LIST[i].isEditable) return i;
        }
        return false;
    };

}

decorate(DateInputStore, {
    value: observable,
    updateValues: action
});

export class MonthAndYearInputStore extends DateInputStore {
    static PATTERN_LIST = [
        {isEditable: true, placeholder: "_"},
        {isEditable: true, placeholder: "_"},
        {isEditable: false, placeholder: " "},
        {isEditable: false, placeholder: "/"},
        {isEditable: false, placeholder: " "},
        {isEditable: true, placeholder: "_"},
        {isEditable: true, placeholder: "_"},
        {isEditable: true, placeholder: "_"},
        {isEditable: true, placeholder: "_"},
    ];
    static AMOUNT_OF_EDITABLES = MonthAndYearInputStore.PATTERN_LIST.filter(({isEditable}) => isEditable).length;
    static FULL_PATTERN = /^([01][0-9]) \/ ([12][0-9]{3})$/;

    updateValue(newValue) {
        if (!newValue) {
            this.value = "";
            return this;
        }
        this.value = (typeof newValue === "object") ?
            this.formatToMatchPattern(
                `
                    ${newValue.month.toString().padStart(2, 0)} / 
                    ${newValue.year}
                `
            ) : this.value = newValue;
        return this;
    }
    
    /**
     * Determine if a value matches the FULL_PATTERN for a date and returns a date object if so
     * @param {string} value
     * @returns {object|boolean}
     *  {month:{number}, day:null, year:{number}}
     */
    getDate = (value = this.value) => {
        if (this.constructor.FULL_PATTERN.test(value)) {
            const {1: month, 2: year} = this.constructor.FULL_PATTERN.exec(value);
            return {
                month: parseInt(month),
                day: null,
                year: parseInt(year)
            };
        }
        return false;
    };
}
decorate(MonthAndYearInputStore, {
    value: observable,
    updateValues: action
});
