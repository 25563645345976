import React from "react";
import PropTypes from "prop-types";
import RawDataVehicleHeader from "./raw-data-vehicle-header";
import VehicleConfigurationHeader from "./vehicle-configuration-header";
import {transformPartnerData} from "./utils";

/**
 *
 * Reusable Vehicle Header that will render the appropriate vehicleHeader
 * will use VSS if provided, then VehicleConfiguration if present and will fallback to partner raw vehicle data
 *
 * @param {object}          vehicleConfiguration - vehicleConfiguration, optional
 * @param {object}          vssConfiguration - vehicle self selection, optional
 * @param {object}          partnerRawVehicleData - partner raw vehicle data, optional
 * @param {string|object}   className -  additional class(es) added to wrapping vehicleConfigurationHeader element, optional
 */
export const VehicleHeader = ({vehicleConfiguration, vssConfiguration, partnerRawVehicleData, className}) => {
    if (vssConfiguration) {
        return <RawDataVehicleHeader {...vssConfiguration}/>;
    }

    if (partnerRawVehicleData) {
        return <RawDataVehicleHeader {...transformPartnerData(partnerRawVehicleData)}/>;
    }

    return (
        <VehicleConfigurationHeader
            name={vehicleConfiguration.name || ""}
            msrp={vehicleConfiguration.msrp || null}
            image={vehicleConfiguration.default_image || null}
            accessoriesAmount={vehicleConfiguration.accessoriesAmount}
            className={className}
        />);
}

VehicleHeader.propTypes = {
    vehicleConfiguration: PropTypes.object,
    vssConfiguration: PropTypes.object,
    partnerRawVehicleData: PropTypes.object,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
