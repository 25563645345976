import React from 'react';
import PropTypes from "prop-types";

import copy from "../copy";
import {ValidatedInput} from "../../components/validated-input"

/**
 * Reusable component for rendering first name form field
 *
 * @param {string}                 value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 controlId - input control id
 */
export function FirstNameField(props) {
    return (
        <ValidatedInput
            required={true}
            {...copy.first_name}
            {...props}
            type="name"
        />
    );
}

FirstNameField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string]),
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    controlId: PropTypes.string, 
};
