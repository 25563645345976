import React from 'react';

import {isInIFrame} from "../../utils/iframe-utils";
import {CTAFooter} from "../../components/cta-footer";
import {ReveoCTAFooter} from "../../components/reveo-cta-footer";

export function SubmitFormButton({canContinue, onClick, continueButtonLabel, oid, partnerStore}) {
    const isRV = partnerStore ? partnerStore.isRV : false;
    const isReveo = partnerStore ? partnerStore.isReveo : false;
    const isRVEXperienceInIframe = isRV && isInIFrame(); 

    if (isReveo && isInIFrame()) {
        return (
            <ReveoCTAFooter
                data-oid={oid}
                disabled={!canContinue}
                label={continueButtonLabel}
                onClick={onClick}
            />
        );
    }
    
    return(
        <CTAFooter
            isSticky={!(isRVEXperienceInIframe)}
            primary={{
                oid,
                isDisabled: !canContinue,
                label: continueButtonLabel,
                onClick: onClick,
            }}
        />
    );
}
