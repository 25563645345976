import React from 'react';
import { inject, observer } from 'mobx-react';
import { theme, ThemeProvider, GlobalStyles } from 'octane-spark';

import { isInIFrame } from '../../utils/iframe-utils';

import { DealerSelector } from './dealer-selector';

export const DealerSelectorContainer = inject(
    'partnerStore',
    'dealerSelectorStore'
)(
    observer(
        class _DealerSelection extends React.Component {
            render() {
                const isFooterSticky =
                    this.props.partnerStore.isRV && isInIFrame();

                // to trigger render when store properties are updated
                const watcher = { ...this.props.dealerSelectorStore };

                return (
                    <>
                        {/* set Global styles from Spark Web Kit */}
                        <GlobalStyles />

                        <ThemeProvider theme={theme}>
                            <DealerSelector
                                zipCodeUser={this.props.zipCodeUser}
                                store={this.props.dealerSelectorStore}
                                isFooterSticky={isFooterSticky}
                                onSubmitDealership={
                                    this.props.onSubmitDealership
                                }
                            />
                        </ThemeProvider>
                    </>
                );
            }
        }
    )
);
