import React from 'react';
import PropTypes from "prop-types";

import copy from "../copy";

import {
    residentialStatusKeys,
    apiResidentialStatuses,
    residentialStatusDescriptions} from "../../enums/residentialStatus";
import {ButtonSelectField} from "../../components/button-select-field";

/**
 * Reusable component for rendering residential status form field
 *
 * @param {string}                 value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 controlId - input control id
 */
const residentialStatusItems = residentialStatusKeys.map(key => ({
    key: apiResidentialStatuses[key], label: residentialStatusDescriptions[key],
}));
export function ResidentialStatusField({onChange, ...props}) {
    const handleChange = (event) => {
        onChange({value: event.key, error: false});
    }

    return (
        <ButtonSelectField
            {...copy.residential_status}
            items={residentialStatusItems}
            selected={props.value}
            onChange={handleChange}
            {...props}
        />
    );
}

ResidentialStatusField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string]),
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    controlId: PropTypes.string, 
};
