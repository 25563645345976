import React from 'react';
import {PropTypes} from 'prop-types';

import {SSNInput} from "../../components/ssn-input"

/**
 * Reusable component for rendering email form field
 *
 * @param {string}                 value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 controlId - input control id
 */
export function SSNField(props) {
    return (
        <SSNInput
            autoValidateAndFormat
            required
            {...props}
        />
    );
}

SSNField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string]),
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    controlId: PropTypes.string, 
};
