import {action, decorate, observable, computed} from 'mobx';
import get from "lodash.get";

import {validateSSN} from "../../utils/validation";
import {formatSSN} from "../../utils/number-utils";

export class SSNRequiredStore {
    // observables
    ssn = null;
    isSSNInvalid = false;

    //references
    partnerStore = null;

    //can continue for button
    get canContinue() {
        return (!!this.ssn && validateSSN(this.ssn));
    }

    constructor({partnerStore}) {
        //saving reference
        this.partnerStore = partnerStore;
    }

    //update ssn
    updateSSNField(ssn, validate = true) {
        this.ssn = formatSSN(ssn);
        if (validate) {
            this.isSSNInvalid = !this.ssn || !validateSSN(this.ssn)
        }
        return this;
    }

}

decorate(SSNRequiredStore, {
    ssn: observable,
    isSSNInvalid: observable,
    updateSSNField: action,
    canContinue: computed,
});
