import {getDateStringFromObject, getTimeSinceDateObject} from "../../utils/date-utils";

/**
 * Some fields must be formatted/computed before being sent to the backend.
 * This object contains mappings to formatting functions .
 */
const BACKEND_FIELDS_FORMATTERS = {
    date_of_birth: {
        dob: getDateStringFromObject,
    },
    residential_date: {
        residential_months: (value) => getTimeSinceDateObject(value).totalMonths,
    },
    yearly_income: {
        gross_monthly_income: (value) => Math.floor(parseInt(value.replace(/\D/g, "")) / 12),
    },
    employer_date: {
        employer_months: (value) => getTimeSinceDateObject(value).months,
        employer_years: (value) => getTimeSinceDateObject(value).years,
    },
};

/**
 * Formats the form data to be on the specific format the API expects.
 * @param {object} fieldStates - fields states object in the format {field_name: {value: any, error: boolean }}
 * @returns Object in the format {field_name: any}.
 */
export const formatFormData = (fieldStates) =>{
    return Object.keys(fieldStates).reduce((formattedData, fieldName) => {
        const fieldState = fieldStates[fieldName];
        const backendFields = BACKEND_FIELDS_FORMATTERS[fieldName];

        if (backendFields) {
            Object.keys(backendFields).forEach((backendFieldName) => {
                const formatter = backendFields[backendFieldName];
                formattedData[backendFieldName] = formatter(fieldState.value);
            });
        } else {
            formattedData[fieldName] = fieldState.value;
        }

        return formattedData;
    }, {});
}

/**
 * Adds partner specific application data into payload object
 * @param {object} data - payload object
 * @param {object} partnerStore - partnerStore
 * @param {object} userStore - userStore
 */
export const addPartnerSpecificApplicationData = (data, partnerStore, userStore) => {
    if(!data){
        throw new Error("Data object was not provided.");
    }

    if(!partnerStore){
        throw new Error("partnerStore was not provided.");
    }

    const newData = {...data}

    /** If meta_data is not defined, creates it */
    newData.meta_data = data.meta_data || {}

    /**
     * Add partner identifier
     */
    if(partnerStore.partnerIdentifier){
        newData.partner = partnerStore.partnerIdentifier;
    }

    /**
     * Add partner origin URL if visiting from a dealer widget
     */
    if (partnerStore.partnerOriginUrl) {
        newData.meta_data.partnerOriginUrl = partnerStore.partnerOriginUrl;
    }

    /**
     * N2O-1621 Used for CycleTrader's AdId, but can be generic as well
     */
    if (partnerStore.productId) {
        newData.meta_data.externalProductId = partnerStore.productId;
    }

    /**
     * Reveo Specific Logic
     */
    if (partnerStore.isReveo) {
        if (partnerStore.partnerLeadId) {
            newData.meta_data.partnerLeadId = partnerStore.partnerLeadId;
        } else {
            console.error('Submitting Reveo application without partnerLeadID')
        }
    }

    /**
     * This is used for backwards compatibility
     * The usage of userStore is being deprecated for new flows
     * We should expect trade_in_intent and coapplicant_intent values for RV partner experiences.
     */
    if (userStore && partnerStore.isRV) {
        newData.trade_in_intent = userStore.userInfo.trade_in_intent === "Yes";
        newData.coapplicant_intent = !!userStore.userInfo.coapplicant_intent;
    }

    return newData;
}

/**
 * Formats the vehicle data to the correct payload format
 * @param {object} vehicle - Vehicle data
 * @param {object} origin - Specifies the vehicle data origin ("widget", "vss" or "uuid")
 */
export const formatVehicleData = (vehicle, origin)=>{
    const validOrigins = ["widget", "vss"];
    const mappings = {
        widget: {
            product_color: "color",
            product_condition: "condition",
            product_id: "product_id",
            product_make: "make",
            product_model: "model",
            product_on_sale: "on_sale",
            product_original_price: "original_price",
            product_price: "price",
            product_stock_number: "stock_number",
            product_vin: "vin",
            product_year: "year",
            product_sales_price: "sales_price",
            product_msrp: "msrp",
            product_oem: "oem",
        },
        vss: {
            raw_condition: "condition",
            raw_make: "make",
            raw_model: "model",
            raw_msrp: "msrp",
            raw_name: "name",
            raw_price: "price",
            raw_type: "product_category",
            raw_year: "year",
            raw_subcategory: "product_subcategory",
        },
    }

    if(!vehicle){
        throw new Error("formatVehicleData: Vehicle object not provided");
    }

    if(!validOrigins.includes(origin)){
        throw new Error("formatVehicleData: Invalid origin");
    }

    const mapping = mappings[origin];

    return Object.keys(vehicle).reduce((agg, vehicleKey)=>{
        const newKey = mapping[vehicleKey];
        // If there's a mapping, rename field, otherwise keep original name
        agg[newKey ? newKey : vehicleKey] = vehicle[vehicleKey];
        return agg;
    }, {origin_type: origin})
}