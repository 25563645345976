import { getRideAPIBaseUrl } from './urls';
import { FLOW_NAMES } from '../../enums/flows';

/**
 * Submits data to a specified endpoint.
 * @param {string} url - url of the target endpoint to submit the data to
 * @param {object} data - payload to submit to the target endpoint
 * @param {string} method - http method to submit the request with
 * @returns Promise resolving to the response
 */
export async function submitForm(url, data, method) {
    if (!url || typeof url !== 'string') {
        throw Error('submitForm received missing or invalid url. Expected non-falsy string but received: ' + url);
    }

    if (!data || Object.keys(data).length === 0 || typeof data !== 'object') {
        throw Error('submitForm received missing or invalid data. Expected a non empty object but received: ' + data);
    }

    const allowedMethods = ['POST', 'PATCH'];
    if (!method || allowedMethods.indexOf(method) === -1) {
        throw Error('submitForm received missing or invalid http method. Expected one of ' + allowedMethods + ' but received: ' + method);
    }

    const fetchOptions = {
        'method': method,
        'headers': { 'Content-Type': 'application/json' },
        'body': JSON.stringify(data)
    };
    const response = await fetch(url, fetchOptions);
    const responseData = await response.json();
    return { success: response.ok, status: response.status, data: responseData };
}


/**
 * Submits data to the v2/applications endpoint for a particular flow application.
 * @param {str} flow_name - string indicating which flow the application belongs to.
 * Must be a value from the FLOW_NAMES enum or else an exception will be thrown.
 * @param {object} data - whatever application data that is relevant for the current step of the application's flow.
 * @returns Promise resolving to an object {success, status, data}.
 */
export async function submitDataForFlowApplication(flow_name, requestData) {
    try {
        const flowUuid = requestData['flow_uuid'] ? requestData['flow_uuid'] + '/' : '';
        const method = flowUuid ? 'PATCH' : 'POST';
        const url = `${getRideAPIBaseUrl()}/api/v2/applications/${flowUuid}`;
        
        if (Object.values(FLOW_NAMES).indexOf(flow_name) === -1) {
            throw new Error('Received invalid flow name: '+ flow_name);
        }
        requestData['flow_name'] = flow_name;
        delete requestData['flow_uuid'];
        
        const response = await submitForm(url, requestData, method);
        const { success, status, data: responseData } = response;
        if (!success) {
            console.error('Error response while submitting data for application (flow: '+ flow_name +'), status: '+ status +' responseData: ', responseData);
        } else {
            console.log('Submitted application data (flow: '+ flow_name +'), status: '+ status +' responseData: ', responseData);
        }
        return { success, status, data: responseData };

    } catch (error) {
        console.error('Error while submitting application (flow: ' + flow_name +')', error);
        throw error;
    }
}
