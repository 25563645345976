import React from 'react';
import PropTypes from 'prop-types';
import get from "lodash.get";
import noop from 'lodash.noop';


export const itemPropType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
        slug: PropTypes.string,
        uuid: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
]);


export class BaseSelectableItems extends React.Component {
    static propTypes = {
        selected: itemPropType,
        defaultSelected: itemPropType,
        items: PropTypes.arrayOf(itemPropType),
        loadItems: PropTypes.func,
        itemValueKey: PropTypes.string,
        itemLabelKey: PropTypes.string,

        // handlers
        onChange: PropTypes.func,
    };

    static defaultProps = {
        onChange: noop,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            items: props.items,
            itemValueKey: this._determineItemValueKey(props),
            itemLabelKey: this._determineItemLabelKey(props),
            selected: props.defaultSelected || props.selected || null,
        };

        if (typeof this.state.selected === 'string' && this.state.itemValueKey) {
            this.state.selected = this.findItemWithValue(this.state.selected);
        }
    }

    componentDidMount() {
        if (this.props.loadItems) {
            this.props.loadItems().then(
                items => this.setState({
                    items,
                    itemValueKey: this._determineItemValueKey({...this.props, items}),
                    itemLabelKey: this._determineItemValueKey({...this.props, items}),
                })
            );
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState(this.determineNewState(newProps));
    }

    /**
     * Called in componentWillReceiveProps to determine the new state based on changed props
     *
     * @param {object} newProps
     * @returns {object}
     */
    determineNewState(newProps) {
        const newState = {};
        if (newProps.items !== this.props.items) {
            newState.items = newProps.items;
            newState.itemValueKey = this._determineItemValueKey(newProps);
            newState.itemLabelKey = this._determineItemLabelKey(newProps);

        } else {
            if (newProps.itemValueKey !== this.props.itemValueKey) {
                newState.itemValueKey = this._determineItemValueKey({...newProps, items: this.state.items});
            }
            if (newProps.itemLabelKey !== this.props.itemLabelKey) {
                newState.itemLabelKey = this._determineItemLabelKey({...newProps, items: this.state.items});
            }
        }

        if (newProps.selected !== this.props.selected) {
            const itemValueKey = newState.itemValueKey || this.state.itemValueKey;
            newState.selected = newProps.selected || null;
            if (typeof newState.selected === 'string' && itemValueKey) {
                newState.selected = this.findItemWithValue(newState.selected);
            }
        }

        return newState;
    }

    /**
     * Handler for when an item is selected
     *  - updates the selected item in state
     *  - calls onChange handler passed into props
     *
     * @param {string} value
     */
    onSelect = value => {
        const previous = this.state.selected;

        if (this.getItemValue(previous) !== value) {
            if (!value) {
                this.setState({selected: null}, () => this.props.onChange(null, previous));
            } else {
                const selected = this.findItemWithValue(value);
                if (selected) {
                    this.setState({selected}, () => this.props.onChange(selected, previous));
                }
            }
        }
    };

    _isNumberOrString = item => ['number', 'string'].includes(typeof item);

    /**
     * Returns the given key from the item object and normalizes the result
     *  - casts any numbers to strings
     *  - returns null for any invalid or undefined values
     *
     * @param {object|string|number|null} item
     * @param {string} [propKey]
     * @returns {string|null}
     */
    _getItemProperty = (item, propKey) => {
        item = propKey ? get(item, propKey, item) : item;
        if (typeof item === 'number') return `${item}`;
        if (typeof item !== 'string') return null;
        return item || null;
    };

    /**
     * Used to determine the key that should be used to get an item's value and label
     * Based on a list of items returns the first key in keyChoices that appears in all the items
     *
     * @param {array} items
     * @param {array} keyChoices
     * @returns {string|null}
     */
    _determineItemKey(items, keyChoices) {
        items = items || [];
        const key = keyChoices.shift();

        for (const item of items) {
            const value = key ? get(item, key) : item;
            const isValid = this._isNumberOrString(value);

            if (!isValid && keyChoices.length) {
                return this._determineItemKey(items, keyChoices);
            } else if (!isValid) {
                return null;
            }
        }

        return key
    }

    /**
     * Based on a list of items and the available properties of each item,
     *  determines the property to use as each item's value
     *
     * @param {array} items
     * @param {string} [itemValueKey]
     * @returns {string|null}
     */
    _determineItemValueKey = ({items, itemValueKey}) => (
        itemValueKey || this._determineItemKey(items, [null, 'key', 'uuid', 'id', 'slug', 'label', 'name'])
    );

    /**
     * Based on a list of items and the available properties of each item,
     *  determines the property to use as each item's label
     *
     * @param {array} items
     * @param {string} [itemLabelKey]
     * @returns {string|null}
     */
    _determineItemLabelKey = ({items, itemLabelKey}) => (
        itemLabelKey || this._determineItemKey(items, [null, 'label', 'name', 'slug', 'key', 'id'])
    );

    /**
     * Helper - Returns the "value" of the given item
     * @param {object} item
     * @returns {string}
     */
    getItemValue = item => this._getItemProperty(item, this.state.itemValueKey);

    /**
     * Helper - Returns the "label" of the given item
     * @param {object} item
     * @returns {string}
     */
    getItemLabel = item => this._getItemProperty(item, this.state.itemLabelKey);

    /**
     * Helper - Returns the full item with the given value
     *
     * @param {string} value
     */
    findItemWithValue = value => this.state.items.find(item => (this.getItemValue(item) === value)) || null;
}
