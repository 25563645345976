import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import {Svg} from "../../components/svg";

/**
 * Component for rendering our octane co-branding header
 *
 * @param {string|object}   [className] - additional class(es) added to header element
 */
export const OctaneCoBrandingHeader = ({className = ""}) => {
    return (
        <div className={classnames("text-center", className)}>
            <h5 className="spark-text-20px-bold mb-0">Instant Decision by <Svg svg="octane-logo" style={{
                height: 22,
                color: "var(--ride-octane-blue)"
            }}/></h5>
            <p className="spark-text-disclosure"> No credit impact <span className="mx-1">&bull;</span> No commitment
            </p>
        </div>
    )
}

OctaneCoBrandingHeader.propTypes = {
    className: PropTypes.string,
};
