import React from 'react';
import PropTypes from "prop-types";

import copy from "../copy";
import {getStates} from "../../../app/utils/get-states"
import {ListDropdown} from "../../components/dropdown";

const stateDropdownItems = getStates();

/**
 * Reusable component for rendering state form field
 *
 * @param {string}                 value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 controlId - input control id
 */
export function StateField({onChange, required = true, ...props}) {
    const handleChange = (event) => {
        onChange({value: event, error: required && !event});
    }
    return (
        <ListDropdown
            {...copy.state}
            items={stateDropdownItems}
            onChange={handleChange}
            {...props}
        />
    );
}

StateField.propTypes = {
    value: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    controlId: PropTypes.string, 
};
