import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import {OfferTile} from "../offer-tile";


export function TransparentOffers(props) {
    return (
        <div>
            {/* TODO: this is temporary, the header is not going to be part of this component (in subsequent PR) */}
            {!!props.showOldHeader && (
                <div className="text-center text-md-right">
                    <p className="spark-text-16px-bold">
                        Offers provided by Roadrunner Financial
                    </p>
                    <p className="spark-text-12px mb-2">
                        Credit approval is valid for 30 days. Rates and promotions are subject to change.
                    </p>
                </div>
            )}
            {props.offers.map((offer, i) => (
                <div key={i} className={classnames({"mt-4": i > 0})}>
                    <OfferTile
                        hideSelectCTA={props.hideSelectCTAs}
                        offer={offer}
                        selectedOffer={props.selectedOffer}
                        onClickSelect={props.onClickSelect}
                    />
                </div>
            ))}
        </div>
    );
}

TransparentOffers.propTypes = {
    // TODO: this is temporary, the header is not going to be part of this component (in subsequent PR)
    showOldHeader: PropTypes.bool,

    hideSelectCTAs: PropTypes.bool,
    offers: PropTypes.array.isRequired,
    selectedOffer: PropTypes.object,
    onClickSelect: PropTypes.func,
};
