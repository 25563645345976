import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {BaseSelectableItems, itemPropType} from "../base-selectable-items";
import {Button, ButtonGroup, Form, FormControl} from "react-bootstrap";


export class ButtonSelectField extends BaseSelectableItems {
    static propTypes = {
        label: PropTypes.string,
        className: PropTypes.string,
        controlId: PropTypes.string, // Sets id on <FormControl> and htmlFor on <FormGroup.Label>.
        variantSelected: PropTypes.string,
        variantUnselected: PropTypes.string,
        error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        success: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

        // controlled component props
        disabled: PropTypes.bool,
        selected: itemPropType,
        items: PropTypes.arrayOf(itemPropType),
        loadItems: PropTypes.func,
        itemValueKey: PropTypes.string,
        itemLabelKey: PropTypes.string,

        // handlers
        onChange: PropTypes.func,
    };

    static defaultProps = {
        variantSelected: "primary",
        variantUnselected: "outline-secondary",
    };

    getItemVariant = item => {
        const {selected} = this.state;
        const isSelected = !!selected && (this.getItemValue(item) === this.getItemValue(selected));
        return isSelected ? this.props.variantSelected : this.props.variantUnselected;
    };

    render() {
        const disabled = this.props.disabled;
        const hasItems = !!this.state.items && !!this.state.items.length;

        const formControlProps = {
            as: 'div',
            className: classnames('p-0', {'border-0': !(this.props.success || this.props.error)}),
            style: {backgroundImage: 'none', height: 'inherit'},
            isValid: !!this.props.success,
            isInvalid: !!this.props.error,
        };

        return (
            <Form.Group className={this.props.className} controlId={this.props.controlId}>
                {/* Label */}
                {this.props.label && (
                    <Form.Label className="d-block">
                        {this.props.label}
                    </Form.Label>
                )}
                {/* Buttons */}
                <Form.Control {...formControlProps}>
                    <ButtonGroup className="d-flex" aria-label={this.props.label}>
                        {!hasItems ? (
                            <Button variant={this.props.variantUnselected} disabled>
                                <div className="spinner-border spark-text-secondary d-block my-n1 mx-auto">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </Button>
                        ) : this.state.items.map((item, i) => {
                            const value = this.getItemValue(item);
                            const label = this.getItemLabel(item);
                            const variant = this.getItemVariant(item);
                            const onClick = () => this.onSelect(value);
                            const style = i === 0 ? {} : {flex: `0 0 ${100 / this.state.items.length}%`};

                            return (
                                <Button key={value} style={style} variant={variant} disabled={disabled}
                                        onClick={onClick} size="lg">
                                    {label}
                                </Button>
                            );
                        })}
                    </ButtonGroup>
                </Form.Control>

                {/* Error / Success Messages */}
                {typeof this.props.success === 'string' && (
                    <FormControl.Feedback type="valid">
                        {this.props.success}
                    </FormControl.Feedback>
                )}
                {typeof this.props.error === 'string' && (
                    <FormControl.Feedback type="invalid">
                        {this.props.error}
                    </FormControl.Feedback>
                )}
            </Form.Group>
        );
    }
}


