import { action, decorate, observable, computed } from "mobx";
import { validateSSN } from "../../../utils/validation";
import { formatSSN } from "../../../utils/number-utils";

export class SSNStepStore {
   // observables
   ssn = null;
   isSSNInvalid = false;
   isSending = false;

   //references
   partnerStore = null;

   //can continue for button
   get canContinue() {
      return !!this.ssn && validateSSN(this.ssn);
   }

   constructor({ partnerStore }) {
      //saving reference
      this.partnerStore = partnerStore;
   }

   //update ssn
   updateSSNField(ssn, validate = true) {
      this.ssn = formatSSN(ssn);
      if (validate) {
         this.isSSNInvalid = !this.ssn || !validateSSN(this.ssn);
      }
      return this;
   }

   updateIsSending(isSending) {
      this.isSending = isSending;
      return this;
   }
}

decorate(SSNStepStore, {
   ssn: observable,
   isSSNInvalid: observable,
   isSending: observable,
   canContinue: computed,
   updateSSNField: action,
   updateIsSending: action,
});
