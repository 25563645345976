import React from 'react';
import PropTypes from "prop-types";

import copy from "../copy";
import {Input} from "../../components/input";
import {validateStreet1} from "../../utils/validation";

/**
 * Reusable component for rendering street1 form field
 *
 * @param {string}                 value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 controlId - input control id
 */
export function Street1Field({error, required = true, ...props}) {
    return (
        <Input
            {...copy.street1}
            autoValidateAndFormat
            required={required}
            validator={validateStreet1}
            error={error && copy.street1.errorMessage}
            onBlur={props.onChange}
            {...props}
        />
    );
}

Street1Field.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string]),
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    controlId: PropTypes.string, 
};
