import React from "react";
import {formatPrice} from "../../utils/number-utils";
import vehiclesTypes from "../../prequal/vehicle-self-selection/vehicles-types";

/* Helper that returns a title with a price next to it */
export const titleWithPrice = (title, ...numbers) => {
    const classes = "spark-text-secondary spark-text-14px";
    const sum = numbers.reduce((acc, val) => Number(acc) + Number(val));

    /* If there is a sum, return the title and price */
    if (sum) {
        return (
            <p className={classes}>
                {title} {formatPrice(sum)}
            </p>
        );
    }
};

/* Helper that transforms partnerStore.rawVehicle data into expected input for rawDataVehicleHeader */
export const transformPartnerData = (partner_data) => {
    const return_dict = {};
    const raw_data_dict = {
        product_condition: 'raw_condition',
        product_year: 'raw_year',
        product_make: 'raw_make',
        product_model: 'raw_model',
        product_price: 'raw_price',
        product_category: 'raw_category',
    };

    // re-formatting dictionary to have expected keys
    const keys = Object.keys(partner_data);
    keys.forEach(item => {
            if (item in raw_data_dict) {
                return_dict[raw_data_dict[item]] = partner_data[item];
            }
        }
    );

    // adding icon to dictionary
    const icon = getVehicleIcon(partner_data['product_category']);
        if (icon) {
            return_dict['raw_icon'] = icon;
        }
    return return_dict;
}

/* Helper that given a vehicle_category will return the corresponding icon from vehicle-types.json */
export const getVehicleIcon = vehicle_category => {
    const vehicle_type_ids = vehiclesTypes.types.map((type) => type.id);
    if (vehicle_type_ids.includes(vehicle_category)) {
        const vehicle_type_values = vehiclesTypes.types.filter(type => type.id === vehicle_category).reduce((acc, x) => acc + x);
        return vehicle_type_values['icon'];
    }
    return null;
}
