import React from "react";
import PropTypes from "prop-types";

import {isInIFrame} from "../../../utils/iframe-utils";

import {OffersLayout} from "../offers-layout";
import copy from "./copy.json";


export function OffersDeclined(props) {
    const uxCopyStore = props.uxCopyStore;
     const isSticky = props.isStickyCTA ?? true;

    // get config for decision block
    const decisionBlock = {
        headline: uxCopyStore.getOffersDeclinedCopy('decision_block.headline', copy.decision_block.headline),
        body: uxCopyStore.getOffersDeclinedCopy('decision_block.body', copy.decision_block.body),
    };

    // get config for ctas block
    let ctasBlock;
    if (props.financeUrl) {
        // if there is a financing url provided (from partner or dealership)
        // then we need to link to that alternative finance url (in a new tab)
        ctasBlock = {
            isSticky: isSticky,
            primary: {
                openLinkInNewTab: true,
                href: props.financeUrl,
                ...copy.primary_cta.financing_alternative,
            },
        };
    } else if (!isInIFrame()) {
        // if there is no financing url provided and not in <iframe/>
        // then fall back to linking to Octane's Financing Process Wordpress page
        ctasBlock = {
            primary: {
                isExternalLink: true,
                ...copy.primary_cta.octane_financing_process,
            },
        };
    }

    return (
        <OffersLayout
            showVehicleHeaderBlock={!!props.vehicleConfiguration && props.showVehicleHeaderBlock}
            accessoriesAmount={props.accessoriesAmount}
            vehicleConfiguration={props.vehicleConfiguration}

            showDecisionBlock
            decisionBlock={decisionBlock}

            // only show contact block if there is contact info from partner/dealership
            showContactBlock={!!props.contactInfo}
            contactInfo={props.contactInfo}
            contactBlock={copy.contact_block}

            // showing the CTA depends on if in <iframe/> or partner/dealership has specified a finance_url
            showCTAsBlock={!!ctasBlock}
            ctasBlock={ctasBlock}
            showBrandingForCampingWorld={props.showBrandingForCampingWorld}
        />
    );
}

OffersDeclined.propTypes = {
    uxCopyStore: PropTypes.object.isRequired,
    accessoriesAmount: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    vehicleConfiguration: PropTypes.shape({
        name: PropTypes.string.isRequired,
        msrp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        default_image: PropTypes.string,
    }),
    contactInfo: PropTypes.shape({
        contact_name: PropTypes.string.isRequired,
        contact_address: PropTypes.arrayOf(PropTypes.string),
        contact_phone_number: PropTypes.string,
        contact_email_address: PropTypes.string,
    }),
    financeUrl: PropTypes.string,
    showVehicleHeaderBlock: PropTypes.bool,
    isStickyCTA: PropTypes.bool,
};
