import React from "react";
import classnames from "classnames";

import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import {RideOctaneLinkContainer} from "../../pages/url-renderer";

import {inject, observer} from "mobx-react"

import styles from "./reveo-cta-footer.module.scss";

/**
 * Reusable component for rendering a Halloween themed custom CTA for our Reveo partner
 * This CTA is meant to match the styles of Reveo's experience
 * The CTA is only hardcoded orange for the Reveo Marketplace aka when theme is 'polaris_blue'
 *
 * @param {string}           [className] - additional class(es) added to header element
 * @param {string}           [label] - the label for the button, it defaults to "Save and Continue"
 * @param {string}           [href] - a relative link for the button to lead to
 */
export const ReveoCTAFooter = inject("themeStore")(observer(({
    themeStore,
    className = "",
    label = "Save and Continue",
    isRelativeLink,
    href,
    ...props
}) => {
    let ctaButton = (
        <Button
            className={classnames(
                styles.reveoButton,
                themeStore.isReveoMarketplace && styles.reveoOrange,
                className
            )}
            {...props}
        >
            {label}
        </Button>
    );

    if (href) {
        ctaButton = (
            <RideOctaneLinkContainer to={href}>
                {ctaButton}
            </RideOctaneLinkContainer>
        );
    }

    return (
        <div className={styles.buttonContainer}>{ctaButton}</div>
    );
}));

ReveoCTAFooter.propTypes = {
    themeStore: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    href: PropTypes.string,
};
