import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import copy from "../copy";
import {purchaseIntents} from "../../enums/purchaseIntent";
import {ButtonSelectField} from "../../components/button-select-field";

/**
 * Reusable component for rendering purchase intent form field
 *
 * @param {string}                 value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 controlId - input control id
 * @param {object}                 apiStore - api store object
 * @param {object}                 partnerStore - partner store object
 */
export function PurchaseIntentField({onChange, apiStore, partnerStore, ...props}) {
    const handleChange = (event) => {
        onChange({value: event.key, error: false});
    }

    const [purchaseIntentOptions, setPurchaseIntentOptions] = useState([]);

    useEffect(() => {
        if(purchaseIntentOptions.length > 0) return;

        const loadPurchaseIntentOptions = async ()=>{
            const purchaseIntentItems = apiStore ? await fetchPurchaseIntentOptions(apiStore) : purchaseIntents;
            setPurchaseIntentOptions(purchaseIntentItems);
        }

        loadPurchaseIntentOptions();
    }, [apiStore, purchaseIntentOptions]);

    const showField = partnerStore? !partnerStore.isEmbeddedPartner : true;

    return showField &&(
        <ButtonSelectField
            {...copy.purchase_intent}
            items={purchaseIntentOptions}
            selected={props.value}
            onChange={handleChange}
            {...props}
        />
    );
}

// Try fetching purchase intents from API. Otherwise use default options
const fetchPurchaseIntentOptions = async (apiStore) => {
        try{
            const {status, response} = await apiStore.fetch("purchase_intent_options");
            if (status !== 200 || !response) {
                throw new Error("Failed to fetch purchase intent options");
            }
            return response.length > 0 ? response : purchaseIntents;
        } catch (error){
            console.error("Failed to fetch purchase intent options", error);
            return purchaseIntents;
        }
    }

PurchaseIntentField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string]),
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    controlId: PropTypes.string, 
};
