import { isWindowDefined } from '../../utils/general-utils';

/**
 * Returns the proper base url for ride api.
 * Note: This should only be used on the client side. For server side usages please import directly from settings
 */

export function getRideAPIBaseUrl() {
    if (!isWindowDefined()) {
        throw new Error('getRideAPIBaseUrl: window is undefined. This function should only be called from the client side.');
    }
    const rideAPIBaseUrlIsSet = !!window.rideOctane && !!window.rideOctane.settings && !!window.rideOctane.settings.BASE_API_URL;
    if (!rideAPIBaseUrlIsSet) {
        throw new Error('getRideAPIBaseUrl: window.rideOctane.settings.BASE_API_URL is invalid or undefined.');
    }
    return window.rideOctane.settings.BASE_API_URL;
}
