import React from 'react';
import PropTypes from "prop-types";

import copy from "../copy";
import {Input} from "../../components/input";

/**
 * Reusable component for rendering street2 form field
 *
 * @param {string}                 value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 controlId - input control id
 */
export function Street2Field({required = false, ...props}) {
    return (
        <Input
            {...copy.street2}
            autoValidateAndFormat
            required={required}
            onBlur={props.onChange}
            {...props}
        />
    );
}

Street2Field.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string]),
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    controlId: PropTypes.string, 
};
