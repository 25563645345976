import React from 'react';

import {FancySpinner} from "../../components/spinners/fancy-spinner";
import {LayoutComponent} from '../../components/layout-component';
import {deniedCreditAccess, emailSent} from '../../components/svg/graphics';
import {ReturnHomeCta} from "../../components/cta-footer";


export function OffersLoading({showTransparentOffers}) {
    return (
        <div className="w-500px mw-100 mx-auto my-5 text-center">
            <div className="mx-auto my-5 d-inline-block">
                <FancySpinner/>
            </div>
            <h3 className="px-3 pb-3 font-weight-bold">
                {showTransparentOffers ? (
                    "Just a moment while we find personalized finance offers for you"
                    ): (
                    "Just a moment for your financing decision"
                )}
            </h3>
            <p className="px-2">
                We're performing a soft inquiry credit check which won't affect your score.
            </p>
        </div>
    );
}

const noCreditReportFoundBody = (
    "We're unable to complete your application because your credit report is missing or has been locked.\n\n" +
    "Please reach out to Experian customer support for more information at (888) 397-3742."
);
export function NoCreditReportFound() {
    return (
        <div className="mt-5">
            {/* Layout with svg icon, headline and body text */}
            <LayoutComponent
                icon={deniedCreditAccess}
                headline="Your credit profile is locked or missing"
                bodyText={noCreditReportFoundBody}
            />
            <ReturnHomeCta />
        </div>
    );
}

export function EmailReminderSent() {
    return (
        <div className="mt-5">
            {/* Layout with svg icon, headline and body text */}
            <LayoutComponent
                icon={emailSent}
                headline="Email Reminder Sent"
                bodyText="You can still save up to 2 hours at the dealer by filling out your paperwork ahead of time. Just click the link in the email sent to get started."
            />
            <ReturnHomeCta />
        </div>
    );
}
