import React from 'react';
import {Col, Row} from "react-bootstrap";

import copy from "./copy";
import {CONTACT_PREFERENCES} from "../../enums/contactPreferences";
import {getStates} from "../../utils/get-states";
import {employmentStatuses} from "../../enums/employmentStatus";
import {
    residentialStatusKeys,
    apiResidentialStatuses,
    residentialStatusDescriptions} from "../../enums/residentialStatus";
import {isInIFrame} from "../../utils/iframe-utils";

import {Input} from "../../components/input";
import {DateInput} from "../../components/date-input";
import {ListDropdown} from "../../components/dropdown";
import {ButtonSelectField} from "../../components/button-select-field";
import {CTAFooter} from "../../components/cta-footer";
import {ReveoCTAFooter} from "../../components/reveo-cta-footer";
import {DateInputType} from "../../utils/date-utils";
import styles from "./about-you.module.scss";
import { relationshipToPrimary } from '../../enums/relationshipToPrimary';


function FirstNameInput({first_name, updateFieldValue, fieldNamePrefix=""}) {
    let control = fieldNamePrefix + "first_name"
    const onChange = (e) => {
        updateFieldValue(control, e.target.value, e.type === 'blur')
    }
    return (
        <Input
            {...copy.first_name}
            controlId="first_name"
            defaultValue={first_name.value}
            error={first_name.showErrorState}
            onBlur={onChange}
            onChange={onChange}
        />
    );
}


function LastNameInput({last_name, updateFieldValue, fieldNamePrefix=""}) {
    let control = fieldNamePrefix + "last_name"
    const onChange = (e) => {
        updateFieldValue(control, e.target.value, e.type === 'blur')
    }
    return (
        <Input
            {...copy.last_name}
            controlId="last_name"
            defaultValue={last_name.value}
            error={last_name.showErrorState}
            onBlur={onChange}
            onChange={onChange}
        />
    );
}

export function FirstNameInputAndLastNameInput({first_name, last_name, updateFieldValue, fieldNamePrefix=""}) {
    return (
        <Row className={styles.names}>
            <Col xs={6}>
                <FirstNameInput
                    first_name={first_name}
                    updateFieldValue={updateFieldValue}
                    fieldNamePrefix={fieldNamePrefix}
                />
            </Col>
            <Col xs={6}>
                <LastNameInput
                    last_name={last_name}
                    updateFieldValue={updateFieldValue}
                    fieldNamePrefix={fieldNamePrefix}
                />
            </Col>
        </Row>
    );
}


export function DateOfBirthPicker({date_of_birth, updateFieldValue, fieldNamePrefix=""}) {
    let control = fieldNamePrefix + "date_of_birth"
    const onChange = (e, date) => {
        updateFieldValue(control, date, e.type === 'blur')
    }
    return (
        <DateInput
            {...copy.date_of_birth}
            controlId="date_of_birth"
            value={date_of_birth.value}
            error={date_of_birth.showErrorState}
            onBlur={onChange}
            onChange={onChange}
            inputType={DateInputType.FULL_DATE}
            className={styles.dob}
        />
    );
}


export function EmailInput({email, updateFieldValue, fieldNamePrefix=""}) {
    let control = fieldNamePrefix + "email"
    const onChange = (e) => {
        updateFieldValue(control, e.target.value, e.type === 'blur')
    }
    return (
        <Input
            {...copy.email}
            controlId="email"
            defaultValue={email.value}
            error={email.showErrorState}
            onBlur={onChange}
            onChange={onChange}
            className={styles.email}
        />
    );
}


export function Street1Input({street1, updateFieldValue, fieldNamePrefix="", disabled=false}) {
    let control = fieldNamePrefix + "street1"
    const onChange = (e) => {
        updateFieldValue(control, e.target.value, e.type === 'blur')
    }
    return (
        <Input
            {...copy.street1}
            controlId="street1"
            value={street1.value}
            error={street1.showErrorState && copy.street1.error_message}
            onBlur={onChange}
            onChange={onChange}
            className={styles.street1}
            disabled={disabled}
        />
    );
}


export function Street2Input({street2, updateFieldValue, fieldNamePrefix="", disabled=false}) {
    let control = fieldNamePrefix + "street2"
    const onChange = (e) => {
        updateFieldValue(control, e.target.value, e.type === 'blur')
    }
    return (
        <Input
            {...copy.street2}
            controlId="street2"
            value={street2.value}
            error={street2.showErrorState}
            onBlur={onChange}
            onChange={onChange}
            className={styles.street2}
            disabled={disabled}
        />
    );
}


export function CityInput({city, updateFieldValue, fieldNamePrefix="", disabled=false}) {
    let control = fieldNamePrefix + "city"
    const onChange = (e) => {
        updateFieldValue(control, e.target.value, e.type === 'blur')
    }
    return (
        <Input
            {...copy.city}
            controlId="city"
            value={city.value}
            error={city.showErrorState && copy.city.error_message}
            onBlur={onChange}
            onChange={onChange}
            className={styles.city}
            disabled={disabled}
        />
    );
}


const stateDropdownItems = getStates();
function StateDropdown({state, updateFieldValue, fieldNamePrefix="", disabled=false}) {
    let control = fieldNamePrefix + "state"
    return (
        <ListDropdown
            {...copy.state}
            controlId="state"
            items={stateDropdownItems}
            selected={state.value}
            error={state.showErrorState}
            onChange={state => updateFieldValue(control, state)}
            disabled={disabled}
        />
    );
}


function ZipCodeInput({zip_code, updateFieldValue, fieldNamePrefix="", disabled=false}) {
    let control = fieldNamePrefix + "zip_code"
    const onChange = (e) => {
        updateFieldValue(control, e.target.value, e.type === 'blur')
    }
    return (
        <Input
            {...copy.zip_code}
            controlId="zip_code"
            value={zip_code.value}
            type="text"
            inputMode="numeric"
            error={zip_code.showErrorState}
            onBlur={onChange}
            onChange={onChange}
            disabled={disabled}
        />
    );
}

export function StateDropdownAndZipCodeInput({state, zip_code, updateFieldValue, fieldNamePrefix="", disabledZip=false, disabledState=false}) {
    return (
        <Row className={styles.stateZip}>
            <Col xs={6}>
                <StateDropdown
                    state={state}
                    updateFieldValue={updateFieldValue}
                    fieldNamePrefix={fieldNamePrefix}
                    disabled={disabledState}
                />
            </Col>
            <Col xs={6}>
                <ZipCodeInput
                    zip_code={zip_code}
                    updateFieldValue={updateFieldValue}
                    fieldNamePrefix={fieldNamePrefix}
                    disabled={disabledZip}
                />
            </Col>
        </Row>
    );
}


const residentialStatusItems = residentialStatusKeys.map(key => ({
    key: apiResidentialStatuses[key], label: residentialStatusDescriptions[key],
}));
export function ResidentialStatusBtns({residential_status, updateFieldValue, fieldNamePrefix=""}) {
    let control = fieldNamePrefix + "residential_status"
    return (
        <ButtonSelectField
            {...copy.residential_status}
            controlId="residential_status"
            items={residentialStatusItems}
            selected={residential_status.value}
            error={residential_status.showErrorState}
            onChange={selected => updateFieldValue(control, selected.key)}
        />
    );
}


export function ResidentialDateInput({residential_date, updateFieldValue, fieldNamePrefix=""}) {
    let control = fieldNamePrefix + "residential_date"
    const onChange = (e, date) => {
        updateFieldValue(control, date, e.type === 'blur')
    }
    return (
        <DateInput
            {...copy.residential_date}
            controlId="residential_date"
            value={residential_date.value}
            error={residential_date.showErrorState}
            onBlur={onChange}
            onChange={onChange}
            inputType={DateInputType.MONTH_AND_YEAR}
        />
    );
}


export function IncomeInput({yearly_income, updateFieldValue, fieldNamePrefix=""}) {
    let control = fieldNamePrefix + "yearly_income"
    // if we have an error, check if we have a message to use otherwise use a boolean
    const onChange = (e) => {
        updateFieldValue(control, e.target.value, e.type === 'blur')
    }
    return (
        <Input
            {...copy.yearly_income}
            controlId="yearly_income"
            value={yearly_income.value}
            type="text"
            inputMode="numeric"
            error={yearly_income.showErrorState && copy.yearly_income.error_message}
            onBlur={onChange}
            onChange={onChange}
            toolTipText={copy.yearly_income.toolTipText}
            toolTipMsg={copy.yearly_income.toolTipsMsg}
        />
    );
}

export function PurchaseIntentBtns({purchase_intent, updateFieldValue, purchaseIntentOptions}) {
    return (
        <ButtonSelectField
            {...copy.purchase_intent}
            controlId="purchase_intent"
            items={purchaseIntentOptions}
            selected={purchase_intent.value}
            error={purchase_intent.showErrorState}
            onChange={selected => updateFieldValue('purchase_intent', selected.key)}
        />
    );
}


const employmentStatusItems = employmentStatuses.map(({id, name}) => ({key: id, label: name}));
export function EmploymentStatusDropdown({employment_status, updateFieldValue, fieldNamePrefix=""}) {
    let control = fieldNamePrefix + "employment_status"
    return (
        <ListDropdown
            {...copy.employment_status}
            controlId="employment_status"
            items={employmentStatusItems}
            selected={employment_status.value}
            error={employment_status.showErrorState}
            onChange={selected => updateFieldValue(control, selected.key)}
        />
    );
}

export function ContactPreferenceBtns({contact_preference, updateContactPreference, contactPreferenceOptions = Object.keys(CONTACT_PREFERENCES)}) {
    // construct array of items for the ButtonSelectField
    const contactPreferenceItems = contactPreferenceOptions.map(key => ({'key': key, 'label': CONTACT_PREFERENCES[key]}));
    return (
        <ButtonSelectField
            {...copy.contact_preference}
            controlId="contact_preference"
            selected={contact_preference}
            items={contactPreferenceItems}
            onChange={selected => updateContactPreference(selected.key)}
        />
    );
}

export function PhoneNumberInput({phone_number, updateFieldValue, fieldNamePrefix=""}) {
    let control = fieldNamePrefix + "phone_number"
    const onChange = (e) => {
        updateFieldValue(control, e.target.value, e.type === 'blur')
    }
    return (
        <Input
            {...copy.phone_number}
            controlId="phone_number"
            type="text"
            inputMode="numeric"
            value={phone_number.value}
            error={phone_number.showErrorState && copy.phone_number.error_message}
            onBlur={onChange}
            onChange={onChange}
        />
    );
}

export const TradeInIntentInput = ({trade_in_intent, updateFieldValue}) => {
    return (
        <ButtonSelectField
            {...copy.trade_in_intent}
            controlId="trade_in_intent"
            selected={trade_in_intent && trade_in_intent.value}
            className={styles.tradeInIntent}
            onChange={(event) => {
                updateFieldValue("trade_in_intent", event.key);
            }}
        />
    );
}

export const CoapplicantIntentInput = ({coapplicant_intent, updateFieldValue, isCoapplicantDtc=false}) => {
    let showConfirmation = coapplicant_intent && coapplicant_intent.value
    return (
        <div className={styles.coapplicantIntent}>
            <Input
                {...copy.coapplicant_intent}
                checked={coapplicant_intent && coapplicant_intent.value}
                type="checkbox"
                controlId="coapplicant_intent"
                onChange={(event) => {
                    updateFieldValue("coapplicant_intent", event.target.checked);
                }}
            />
            {showConfirmation && (
                <p className="spark-text-success coapplicant-confirmation">
                    {isCoapplicantDtc ? copy.coapplicant_dtc_confirmation : copy.coapplicant_confirmation}
                </p>
            )}
        </div>
    );
}

const relationshipItems = relationshipToPrimary.map(({id, name}) => ({key: id, label: name}));
export const RelationshipToPrimaryDropdown = ({relationship_to_primary, updateFieldValue}) => {
    return (
        <ListDropdown
            {...copy.relationship_to_primary}
            controlId="relationship_to_primary"
            items={relationshipItems}
            selected={relationship_to_primary.value}
            error={relationship_to_primary.showErrorState}
            onChange={selected => updateFieldValue('relationship_to_primary', selected.key)}
        />
    );
}

export const ReusePrimaryApplicantAddressInput = ({reusePrimaryApplicantAddressForCoapplicant}) => {
    return (
        <Input
            {...copy.coapplicant_reuse_address}
            type="checkbox"
            controlId="reuse_primary_applicant_address"
            onChange={reusePrimaryApplicantAddressForCoapplicant}
        />
    );
}

export function SubmitAboutYouFormButton({canContinue, onClick, continueButtonLabel, isReveo, isRV, fieldNamePrefix=""}) {
    const fieldNamePrefixNoUnderscore = fieldNamePrefix.replace("_", "-");
    const oid = fieldNamePrefixNoUnderscore + 'cta-about-you';
    const isRVEXperienceInIframe = isRV && isInIFrame();

    /* We only render custom Reveo CTAs if we're in an iFrame */
    if (isReveo && isInIFrame()) {
        return (
            <ReveoCTAFooter
                data-oid={oid}
                disabled={!canContinue}
                label={continueButtonLabel}
                onClick={onClick}
            />
        );
    }
    
    return(
        <CTAFooter
            isSticky={!(isRVEXperienceInIframe)}
            primary={{
                oid,
                isDisabled: !canContinue,
                label: continueButtonLabel,
                onClick: onClick,
            }}
        />
    );
}
