import {decorate, observable, computed, action} from 'mobx';
import get from "lodash.get";
import template from "lodash.template";

import {CONTACT_PREFERENCES} from "../../enums/contactPreferences";
import {rv_payload_schema, reveo_payload_schema, validatePayload, validateMatchingDomains} from "../../utils/validation";
import copy from "./copy";

import getStateCode from '../../utils/get-state-code';
import { getStates } from '../../utils/get-states';


export class AboutYouStore {
    userStore = null;
    apiStore = null;
    uxCopyStore = null;
    partnerStore = null;
    dealershipStore = null;
    rideOctaneStore = null;
    hasInteractedWithForm = false;
    hasInteractedWithCoapplicantForm = false;
    hasFetchedPurchaseIntentOptions = false;

    // observables
    prequalDisclosureConsent = false;
    coapplicantPrequalDisclosureConsent = false;
    textConsent = false;
    coapplicantTextConsent = false;
    purchaseIntentOptions = null;
    shouldShowErrorStates = {};
    coapplicantFieldsList = [];
    disableAddressFields = false;

    // just used on RV experiences
    userAddressInfoLocked = false;
    userAddressStateMapped = false;

    get showValidationStates() {
        return this.prequalDisclosureConsent || this.hasInteractedWithForm;
    }

    get showCoapplicantValidationStates() {
        return this.coapplicantPrequalDisclosureConsent || this.hasInteractedWithCoapplicantForm;
    }

    getFieldValueByName(fieldName, fieldNamePrefix="", showErrorState=true)
    {
        if(fieldName === "contact_preference") {
            return this.userStore.contact_preference;
        }
        else {
        return this._getNewFieldState(fieldNamePrefix + fieldName, showErrorState);
        }
    }

    _getNewFieldState(field, isRequired = true) {
        const formatter = this.userStore.constructor.USER_INFO_FORMATTER[field];
        const raw_value = this.userStore.userInfo[field];
        const value = formatter ? formatter.formatted_value(raw_value || '') : raw_value;

        const isCoapplicantField = this.coapplicantFieldsList.includes(field);
        const skipValidation = !isRequired || (!isCoapplicantField && !this.showValidationStates) || (isCoapplicantField && !this.showCoapplicantValidationStates);
        if (skipValidation) {
            return {
                value,
                showErrorState: false,
            };
        }

        const isValid = this.userStore.isUserInfoValid(field);
        const shouldShowErrorState = this.shouldShowErrorStates[field];
        return {
            value,
            showErrorState: !isValid && shouldShowErrorState,
        };
    }

    canContinue(isCoapplicantFlow=false, fieldNamePrefix="") {
        let contact_preference_selection_is_valid = false;
        // check that the customer has consented
        if(!isCoapplicantFlow)
        {
            if (!this.prequalDisclosureConsent || !this.textConsent) {
                return false;
            }

            if (this.userStore["contact_preference"] === 'contact_by_text') {
                contact_preference_selection_is_valid = this.userStore.isUserInfoValid(fieldNamePrefix + 'phone_number');
            }
            if (this.userStore["contact_preference"] === 'contact_by_phone') {
                contact_preference_selection_is_valid = this.userStore.isUserInfoValid(fieldNamePrefix + 'phone_number');
            }
            if (this.userStore["contact_preference"] === 'contact_by_email') {
                contact_preference_selection_is_valid = this.userStore.isUserInfoValid(fieldNamePrefix + 'email');
            }
        }
        else
        {
            if (!this.coapplicantPrequalDisclosureConsent) {
                return false;
            }
        }

        // check all fields are valid
        return (
            this.userStore.isUserInfoValid(fieldNamePrefix + 'first_name') &&
            this.userStore.isUserInfoValid(fieldNamePrefix + 'last_name') &&
            this.userStore.isUserInfoValid(fieldNamePrefix + 'date_of_birth') &&
            this.userStore.isUserInfoValid(fieldNamePrefix + 'email') &&
            this.userStore.isUserInfoValid(fieldNamePrefix + 'street1') &&
            (!this.userStore.userInfo[fieldNamePrefix + 'street2'] || this.userStore.isUserInfoValid(fieldNamePrefix + 'street2')) && // not required
            this.userStore.isUserInfoValid(fieldNamePrefix + 'city') &&
            this.userStore.isUserInfoValid(fieldNamePrefix + 'state') &&
            this.userStore.isUserInfoValid(fieldNamePrefix + 'zip_code') &&
            this.userStore.isUserInfoValid(fieldNamePrefix + 'residential_status') &&
            this.userStore.isUserInfoValid(fieldNamePrefix + 'residential_date') &&
            this.userStore.isUserInfoValid(fieldNamePrefix + 'yearly_income') &&
            this.userStore.isUserInfoValid(fieldNamePrefix + 'employment_status') &&
            this.userStore.isUserInfoValid(fieldNamePrefix + 'phone_number') &&
            (isCoapplicantFlow || contact_preference_selection_is_valid) &&
            (isCoapplicantFlow || this.userStore.isUserInfoValid('purchase_intent')) &&
            (!isCoapplicantFlow || this.userStore.isUserInfoValid('relationship_to_primary')) &&
            (!(this.partnerStore.isCoapplicantEnabled && this.getFieldValueByName('coapplicant_intent').value)
            || this.userStore.isUserInfoValid(fieldNamePrefix + 'ssn'))
        );
    }

    getContinueButtonLabel(routesToCoapplicant=false) {
        const copyKey = routesToCoapplicant ? 'continue_button.add_coapplicant_label' : 'continue_button.label';
        return this.uxCopyStore.getAboutYouCopy(copyKey) || get(copy, copyKey) || "";
    }

    /**
     * Computed value that returns which about you form header to show.
     * Defaults to the vehicle header.
     * For partner experiences, it return the partner's about_you_header option or null.
     * @returns {string}
     */
    get aboutYouHeaderType() {
        const validHeaderTypes = ['VehicleHeader', 'DualValuePropHeader', 'TripleValuePropHeader', 'OctaneCoBrandingHeader'];
        let aboutYouHeader = validHeaderTypes[0];

        // If this is a partner experience, grab the aboutYouHeader from there or use null
        if (this.partnerStore.partner) {
            aboutYouHeader = validHeaderTypes
                .find(header => header === this.partnerStore.partner.about_you_header) || null;
        }

        return aboutYouHeader;
    }

    get isVehicleRequiredToRender() {
        return this.aboutYouHeaderType === 'VehicleHeader';
    }

    get showPoweredByOctane() {
        // show it unless a partner has explicitly disabled it
        return get(this.partnerStore, 'partner.show_powered_by_octane') !== false;
    }

    get showContactPreferenceButtons() {
        return !!this.contactPreferenceOptions && this.contactPreferenceOptions.length > 1;
    }

    get showPurchaseIntentButtons() {
        return !this.partnerStore.isEmbeddedPartner && !!this.purchaseIntentOptions && this.purchaseIntentOptions.length > 1;
    }

    get showVehicleFinancingUnavailable() {
        return !(this.partnerStore.partner && this.partnerStore.partner.is_brp);
    }

    /**
     * Computed value that returns if the partner consent checkbox is going to be shown
     * in the about you form for partner experiences.
     * @return {boolean} 
     */
    get showConsentTextCheckbox() {
        return !!(this.partnerStore.partner && this.partnerStore.partner.show_about_you_consent_text);
    }


    /**
     * Computed value that returns the content from the UX copy with the tag about-you.show_consent_text
     * in order to be rendered in the partner consent checkbox.
     * @return {sstring}
     */
    get showConsentText() {
        return this.uxCopyStore.getAboutYouCopy('show_consent_text') || "";
    }

    get showServerErrorView() {
        return (
            (this.hasFetchedPurchaseIntentOptions && !this.purchaseIntentOptions) ||
            (this.partnerStore.isMultiDealershipEnabled && !this.dealershipStore.externalDealershipId)
        );
    }

    get serverErrorMessage() {
        let errorMessage = "";

        if(this.hasFetchedPurchaseIntentOptions && !this.purchaseIntentOptions) 
            errorMessage += "No purchase intent options available. ";

        if(this.partnerStore.isMultiDealershipEnabled && !this.dealershipStore.externalDealershipId) 
            errorMessage += "Multi dealership enabled but no externalDealershipId is given. ";

        return errorMessage;
    }

    get contactPreferenceOptions() {
        // set defaultContactPreferenceOptions to an array with all of the keys in the CONTACT_PREFERENCES enum
        const defaultContactPreferenceOptions = Object.keys(CONTACT_PREFERENCES);

        // get contact preferences from partner (when available), and filter out any invalid values
        const partnerContactPreferences = (get(this.partnerStore, 'partner.contact_preference_options') || []).filter(contactPreferenceOption => {
            const isValidOption = defaultContactPreferenceOptions.includes(contactPreferenceOption);
            if (!isValidOption) {
                console.error('Skipping invalid contact preference option:', contactPreferenceOption);
            }
            return isValidOption;
        })

        // return partnerContactPreferences (if populated), otherwise return defaultContactPreferenceOptions (all keys from the CONTACT_PREFERENCES enum)
        return !!partnerContactPreferences && partnerContactPreferences.length > 0 ? partnerContactPreferences : defaultContactPreferenceOptions;
    }

    /** Note: Name and privacy policy URL must be filled out at the partner and/or dealership level to add additional policy */
    get prequalDisclosure() {
        const dealership = this.dealershipStore.dealership;
        const partner = this.partnerStore.partner;
        let name, privacyPolicyURL;

        /* For Reveo we don't want to show either partner or dealer privacy policy as it's in their experience */
        if (!this.partnerStore.isReveo) {
            // if dealership is populated and it has privacy_policy_url set, use it to construct the disclosure
            if (dealership && dealership.name && dealership.privacy_policy_url) {
                name = dealership.name;
                privacyPolicyURL = dealership.privacy_policy_url;
            }
            // if partner is populated and it has privacy_policy_url set, use it to construct the disclosure
            else if (partner && partner.name && partner.privacy_policy_url) {
                name = partner.name;
                privacyPolicyURL = partner.privacy_policy_url;
            }
        }

        // if enable_alternate_lenders enabled, need to render markdown with alternate lenders disclosure
        if (partner && get(partner, 'enable_alternate_lenders')) {
            return template(copy.prequal_disclosure.alternate_lenders_markdown_content)({
                continueButtonLabel: this.getContinueButtonLabel(),
                name,
                privacyPolicyURL,
            });
        }

        // Return the constructed disclosure
        return template(copy.prequal_disclosure.markdown_content)({
            continueButtonLabel: this.getContinueButtonLabel(),
            name,
            privacyPolicyURL,
        });
    };

    constructor({userStore, apiStore, uxCopyStore, dealershipStore, partnerStore, rideOctaneStore}) {
        // save references
        this.userStore = userStore;
        this.apiStore = apiStore;
        this.uxCopyStore = uxCopyStore;
        this.dealershipStore = dealershipStore;
        this.partnerStore = partnerStore;
        this.rideOctaneStore = rideOctaneStore;

        // prevents validation before interaction
        this.hasInteractedWithForm = false;
        this.hasInteractedWithCoapplicantForm = false;

        // Fetched the list of coapplicant fields from user-store for later validations.
        this.coapplicantFieldsList = this.userStore.getCoapplicantFields();

        // set default values
        const defaultFieldValues = {
            residential_status: "O",
            purchase_intent: "WITHIN_A_WEEK",
            employment_status: "employed",
            coapplicant_residential_status: "O",
            coapplicant_employment_status: "employed",
        };
        // trade_in_intent is only for RV partner experiences
        if (this.partnerStore && this.partnerStore.isRV) {
            defaultFieldValues["trade_in_intent"] = copy.trade_in_intent.items[0].key; // Yes
        }
        Object.keys(defaultFieldValues).forEach((field) => {
            if (this.userStore.userInfo[field] === null) {
                this.userStore.updateUserInfoField(field, defaultFieldValues[field]);
            }
        });

        this.userStore.constructor.USER_INFO.forEach((field) => this.shouldShowErrorStates[field] = false);

        // if userStore.contact_preference is not set, set it to the first available option
        if (this.userStore.contact_preference === null && !!this.contactPreferenceOptions && this.contactPreferenceOptions.length > 0) {
            this.userStore.setContactPreference(this.contactPreferenceOptions[0]);
        }
        if (this.userStore.coapplicant_contact_preference === null && !!this.contactPreferenceOptions && this.contactPreferenceOptions.length > 0) {
            this.userStore.setCoapplicantContactPreference(this.contactPreferenceOptions[0]);
        }
    }

    /**
     * Update a single field in the aboutYou object
     *
     * @param {string} field
     * @param {*} value
     * @param {boolean} [forceUpdateFieldState] - defaults to true
     */
    updateFieldValue = (field, value, forceUpdateFieldState = true) => {
        const isCoapplicantField = this.coapplicantFieldsList.includes(field);
        const hasInteracted = "hasInteractedWith" + (isCoapplicantField ? "Coapplicant" : "") + "Form";
        this[hasInteracted] = true;

        if (forceUpdateFieldState) {
            this.shouldShowErrorStates[field] = true;
        }
        this.userStore.updateUserInfoField(field, value);
        return this;
    };

    resetShouldShowErrorStatesForBlankValues = () => {
        Object.keys(this.shouldShowErrorStates).forEach((field) => {
            if (this.userStore.userInfo[field] === "" || this.userStore.userInfo[field] === null)
            {
                this.shouldShowErrorStates[field] = false;
            }
        });
    }

    updatePrequalDisclosureConsent = prequalDisclosureConsent => {
        this.prequalDisclosureConsent = prequalDisclosureConsent;
        Object.keys(this.shouldShowErrorStates).forEach((field) => {
            this.shouldShowErrorStates[field] = !this.coapplicantFieldsList.includes(field);
        });
        //If form is clean and user unchecks prequal disclosure consent, reset all validation states to false
        if(!prequalDisclosureConsent && !this.hasInteractedWithForm)
        {
            this.resetShouldShowErrorStatesForBlankValues();
        }
        return this;
    };

    reusePrimaryApplicantAddressForCoapplicant = (event) => {
        const addressFieldsList = ['street1', 'street2', 'city', 'state', 'zip_code'];
        const fieldNamePrefix = "coapplicant_";

        addressFieldsList.forEach(field => {
            // Set primary applicant's address data as coapplicant address
            this.userStore.userInfo[fieldNamePrefix + field] = (this.userStore.userInfo[field] || "");
            this.updateFieldValue(fieldNamePrefix + field, this.userStore.userInfo[field] || "");
        });
        this.disableAddressFields = event.target.checked;
    };

    updateContactPreference = contact_preference => {
        this.userStore.setContactPreference(contact_preference);
        return this;
    };

    updateTextConsent = textConsent => {
        this.textConsent = textConsent;
        return this;
    };

    /**
     * Action method that updates the partner consent when is checked inside the about
     * you form when is rendered because of the show_consent configuration is active.
     */
    updatePartnerConsent = partnerConsent => {
        this.userStore.setPartnerConsent(partnerConsent);
        return this;
    }

    updateCoapplicantPrequalDisclosureConsent = coapplicantPrequalDisclosureConsent => {
        this.coapplicantPrequalDisclosureConsent = coapplicantPrequalDisclosureConsent;
        Object.keys(this.shouldShowErrorStates).forEach((field) => {
            this.shouldShowErrorStates[field] = true;
        });
        //If form is clean and user unchecks prequal disclosure consent, reset all validation states to false
        if(!coapplicantPrequalDisclosureConsent && !this.hasInteractedWithCoapplicantForm)
        {
            this.resetShouldShowErrorStatesForBlankValues();
        }
        return this;
    };

    /**
     * Attempts to fetch the purchase intent options (from api server s3)
     * (ultimately sets hasFetchedPurchase Intent to true)
     */
    fetchPurchaseIntentOptions() {
        let endpoint = 'purchase_intent_options';
                // perform fetch and update store
        return this.apiStore.fetch(endpoint)
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.error('Failed to fetch purchase intent options', {status, response});
                    return null;
                }
                return response;
            })
            .catch(error => {
                console.error('Failed to fetch purchase intent options', error);
                return this.updatePurchaseIntentOptions({});
            })
            .then(purchaseIntentOptions => {
                return this.updatePurchaseIntentOptions({purchaseIntentOptions});
            });
    }

    updatePurchaseIntentOptions({purchaseIntentOptions}) {
        this.hasFetchedPurchaseIntentOptions = true;
        if (purchaseIntentOptions) {
            this.purchaseIntentOptions = purchaseIntentOptions;
        }
        return this;
    }

    /**
     * Message handler to gather reveo customer information
     */
    reveoMessageHandler(event) {
        const valid = validatePayload(reveo_payload_schema, event.data);
        if (valid) {
            if ((this.rideOctaneStore.settings.ENVIRONMENT_KEY !== 'production') ||
                validateMatchingDomains(this.partnerStore.partner.partner_url, event.origin))
            {
                // Pre-populate the form with provided customer info and the partner lead id
                this.userStore.updateUserInfoFields(event.data.customer);
                if (event.data.contact_preference) {
                    this.userStore.setContactPreference(event.data.contact_preference);
                }

                this.partnerStore.partnerOriginUrl = event.origin;
                this.partnerStore.partnerLeadId = event.data.partner_lead_id;

                if (event.data.vehicle) {
                    let model;
                    if (event.data.vehicle.model && event.data.vehicle.trim) {
                        model = `${event.data.vehicle.model} ${event.data.vehicle.trim}`;
                    } else {
                        model = event.data.vehicle.model;
                    }

                    this.partnerStore.rawVehicle = {
                        product_make: event.data.vehicle.make,
                        product_model: model,
                        product_year: event.data.vehicle.year,
                        product_id: event.data.vehicle.reveo_id,
                        product_category: event.data.vehicle.type,
                        product_price: event.data.vehicle.price,
                        product_stock_number: event.data.vehicle.stock_number,
                        product_vin: event.data.vehicle.vin,
                        product_condition: event.data.vehicle.condition,
                    }
                }
            }
            //remove message listener after reveo info is set
            window.removeEventListener('message', this._reveoMessageHandler);
            delete this._reveoMessageHandler;
        } 
        else {
            console.error('invalid data in reveoMessageHandler received');
        }
    }

    rvMessageHandler(event) {
        const valid = validatePayload(rv_payload_schema, event.data);
        if (valid) {
            
            if ((this.rideOctaneStore.settings.ENVIRONMENT_KEY !== 'production') ||
                validateMatchingDomains(this.partnerStore.partner.partner_url, event.origin))
            {

                const rv = event.data.rv_data;
                if (rv) {
                    // this data will be stored on partnerVehicleMatch and used to decision RVs
                    const prices = rv.prices || {};   
                    this.partnerStore.rawVehicle = {
                        product_make: rv.make,
                        product_oem: rv.oem,
                        product_model: rv.model,
                        product_year: rv.year,
                        product_category: 'RV',
                        product_subcategory: rv.rv_type,
                        product_price: rv.price,
                        product_sales_price: prices.sales_price || null,
                        product_msrp: prices.msrp || null,
                        product_condition: rv.condition,
                    };
                }

                this.partnerStore.partnerOriginUrl = event.origin;

                if (event.data.customer) {
                    let state = event.data.customer.state;
                    // if state is full name instead of state code, convert to state code
                    state = getStates().includes(state) ? state : getStateCode(state);
                    const customerData = {
                        ...event.data.customer,
                        state,
                    };
                    this.userStore.updateUserInfoFields(customerData);
                    this.lockUserAddressInfo(!!state);
                }
                if (event.data.coapplicant) {
                    this.userStore.updateUserInfoFields(event.data.coapplicant);
                }
                if (event.data.contact_preference) {
                    this.userStore.setContactPreference(event.data.contact_preference);
                }
                const tokenId = event.data.token_id;
                if (tokenId) {
                    this.partnerStore.partnerTokenId = tokenId;
                }
            }

            //remove message listener after rv info is set
            window.removeEventListener('message', this._rvMessageHandler);
            delete this._rvMessageHandler;
        }
    }

    /**
     * Stats a message handler to gather reveo customer info
     */
    startReveoMessageListener(window) {
        this._reveoMessageHandler = (e) => this.reveoMessageHandler(e);
        window.addEventListener('message', this._reveoMessageHandler);
    }

    startRvMessageListener(window) {
        // save the function call to a new variable
        this._rvMessageHandler = (e) => this.rvMessageHandler(e);

        // add the event listener using the variable you defined 
        // (this is so we can reference the instance to remove it later)
        window.addEventListener('message', this._rvMessageHandler);
    }
        
    /**
     * Locks the user's address info so that it cannot be changed (RV experiences)
     */
    lockUserAddressInfo(withState = false) {
        this.userAddressInfoLocked = true;
        this.userAddressStateMapped = withState;
    }
}

decorate(AboutYouStore, {
    prequalDisclosureConsent: observable,
    coapplicantPrequalDisclosureConsent: observable,
    textConsent: observable,
    partnerConsent: observable,
    coapplicantTextConsent: observable,
    purchaseIntentOptions: observable,
    hasFetchedPurchaseIntentOptions: observable,
    shouldShowErrorStates: observable,
    disableAddressFields: observable,
    userAddressInfoLocked: observable,
    userAddressStateMapped: observable,

    aboutYouHeaderType: computed,
    isVehicleRequiredToRender: computed,
    showContactPreferenceButtons: computed,
    showPurchaseIntentButtons: computed,
    showVehicleFinancingUnavailable: computed,
    showConsentTextCheckbox: computed,
    showConsentText: computed,
    showPoweredByOctane: computed,
    showServerErrorView: computed,
    serverErrorMessage: computed,

    contactPreferenceOptions: computed,
    prequalDisclosure: computed,

    updateFieldValue: action,
    updatePrequalDisclosureConsent: action,
    updateCoapplicantPrequalDisclosureConsent: action,
    reusePrimaryApplicantAddressForCoapplicant: action,
    updateTextConsent: action,
    updatePartnerConsent: action,
    updateCoapplicantTextConsent: action,
    updatePurchaseIntentOptions: action,
    getContinueButtonLabel: action,
    lockUserAddressInfo: action,
});
