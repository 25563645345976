import React from 'react';
import PropTypes from "prop-types";

import copy from "../copy";
import {Input} from "../../components/input";

/**
 * Reusable component for rendering reuse primary applicant address form field
 *
 * @param {boolean}                value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 controlId - input control id
 */
export const ReusePrimaryApplicantAddressField = ({onChange, value,...props}) => {
    const handleChange = (event) => {
        onChange({value: event.target.checked, error: false});
    }
    return (
        <Input
            {...copy.coapplicant_reuse_address}
            type="checkbox"
            controlId="reuse_primary_applicant_address"
            onChange={handleChange}
            checked={value}
            {...props}
        />
    );
}

ReusePrimaryApplicantAddressField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.bool]),
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    controlId: PropTypes.string,
};
