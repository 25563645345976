import React from "react";
import { Container } from "react-bootstrap";
import { LayoutComponent } from "../../../components/layout-component";
import { moreInformation } from "../../../components/svg/graphics";

import copy from "./copy";
import { SSNInput } from "../../../components/ssn-input";
import { observer } from "mobx-react";
import { isInIFrame } from "../../../utils/iframe-utils";
import { CTAFooter } from "../../../components/cta-footer";
import { ReveoCTAFooter } from "../../../components/reveo-cta-footer";

const SsnStep = ({ store, isPartnerReveo, isPartnerRV, onSubmitSSN }) => {
   const inIFrame = isInIFrame();
   
   const handleChangeSSN = (e) => store.updateSSNField(e.target.value, e.type === "blur");
   
   const handleSubmitSSN = () => {
      store.updateIsSending(true);
      onSubmitSSN(store.ssn);
   };

   return (
      <Container>
         <div data-oid="ssn-required-page" className="w-315px mw-100 mx-auto">
            {/* Layout with svg icon, headline and body text */}
            <LayoutComponent
               className="mt-5"
               icon={moreInformation}
               headline={copy.text.header}
               bodyText={copy.text.subtitle}
            />

            <div className="mt-5">
               <SSNInput
                  {...copy.ssn}
                  onChange={handleChangeSSN}
                  value={store.ssn}
                  error={store.isSSNInvalid}
               />
            </div>

            {/* Footer w/ action button */}

            {/* Partner Reveo and in iframe */}
            {isPartnerReveo && inIFrame && (
               <ReveoCTAFooter
                  oid="cta-ssn-required"
                  label={copy.submit_button.label}
                  disabled={!store.canContinue || store.isSending}
                  onClick={handleSubmitSSN}
               />
            )}

            {/* Partner RV and not in iframe */}
            {!(isPartnerReveo && inIFrame) && (
               <CTAFooter
                  primary={{
                     oid: "cta-ssn-required",
                     label: copy.submit_button.label,
                     isDisabled: !store.canContinue || store.isSending,
                     onClick: handleSubmitSSN,
                  }}
                  isSticky={!(isPartnerRV && inIFrame)}
               />
            )}
         </div>
      </Container>
   );
};

export default observer(SsnStep);
