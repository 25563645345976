import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';
import {Redirect} from "react-router-dom";
import {Container} from "react-bootstrap";

import {moreInformation} from "../../components/svg/graphics";
import {LayoutComponent} from "../../components/layout-component";
import {CTAFooter} from "../../components/cta-footer";
import {SSNInput} from "../../components/ssn-input";

import {SSNRequiredStore} from './ssn-required-store'

import copy from './copy';
import {ReveoCTAFooter} from "../../components/reveo-cta-footer";
import {isInIFrame} from "../../utils/iframe-utils";


export const SSNRequired = inject('partnerStore')(observer(({
                                                                partnerStore,
                                                                hasCompletedAboutYouForm,
                                                                onSubmitSSN,
                                                                offersUrl,
                                                                aboutYouPrequalUrl,
                                                                applicationUuid,
                                                                hasSubmittedSSN
                                                            }) => {
    const store = new SSNRequiredStore(partnerStore);

    const SSNRequiredTitle = () => (
        /* Layout with svg icon, headline and body text */
        <LayoutComponent
            className="mt-5"
            icon={moreInformation}
            headline={copy.text.header}
            bodyText={copy.text.subline}
        />
    );

    const SSNInputObservable = observer(() => (
        <div className="mt-5">
            <SSNInput
                {...copy.ssn}
                onChange={onChangeSSN}
                value={store.ssn}
                error={store.isSSNInvalid}
            />
        </div>
    ));

    const SubmitSSNButton = observer(() => {
        const inIFrame = isInIFrame();
        if (partnerStore.isReveo && inIFrame) {
            return (
                <ReveoCTAFooter
                    oid='cta-ssn-required'
                    label={copy.submit_button.label}
                    disabled={!store.canContinue}
                    onClick={() => onSubmitSSN(store.ssn)}
                />
            );
        }

        return (
            <CTAFooter
                primary={{
                    oid: 'cta-ssn-required',
                    label: copy.submit_button.label,
                    isDisabled: !store.canContinue,
                    onClick: () => onSubmitSSN(store.ssn),
                }}
                isSticky={!(partnerStore.isRV && inIFrame)}
            />
        );
    });

    const onChangeSSN = e => (
        store.updateSSNField(e.target.value, e.type === 'blur')
    );

    /*
    1. CHECK prequalStore.hasCompletedAboutYouForm is true
    -> redirect to about you form
    2. CHECK if hasSubmittedSSN is true
    -> redirect to offers page
    3. RENDER form w/ ssn input & continue button
    4. On submission of form (after successful post), check that ssn was submitted and navigate to offers page
    */
    if (!hasCompletedAboutYouForm) {
        return (
            <Redirect to={aboutYouPrequalUrl}/>
        );
    }

    if (!applicationUuid || hasSubmittedSSN) {
        return (
            <Redirect to={offersUrl}/>
        );
    }

    return (
        <Container>
            <div data-oid="ssn-required-page" className="w-315px mw-100 mx-auto">
                <SSNRequiredTitle/>
                <SSNInputObservable/>
                <SubmitSSNButton/>
            </div>
        </Container>
    );
}));

SSNRequired.propTypes = {
    hasCompletedAboutYouForm: PropTypes.bool.isRequired,
    onSubmitSSN: PropTypes.func.isRequired,
    offersUrl: PropTypes.string.isRequired,
    aboutYouPrequalUrl: PropTypes.string.isRequired,
    applicationUuid: PropTypes.string.isRequired,
    hasSubmittedSSN: PropTypes.bool.isRequired,
};

