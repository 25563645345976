import React from "react";
import {Route, Switch} from 'react-router-dom';

import {Page} from "./page";
import {withTracker} from "../analytics";

// Stores
import {ApiStore} from "../stores/api-store";
import {UserStore} from "../stores/user-store";

// Components
import {Flows} from "../flows";
import {ReturnToServer} from "../components/return-to-server";

const FlowsWithTracker = withTracker(Flows);

// Flows Page
export function flows(queryParams) {

    const store = Page.createStore({
        isPrequalFlows: true,
        stores: {
            apiStore: new ApiStore(),
            userStore: new UserStore(),
        },
    });

    const component = Page.createComponent({
        content: (
            <Switch>
                {/* Note: these routes are indifferent to trailing slashes */}
                <Route path="/:slug?/apply/:subRoute?/:appUuid?" component={FlowsWithTracker}/>
                <Route component={ReturnToServer} />
            </Switch>
        )
    });

    return {store, component};
}
