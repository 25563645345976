import { decorate, computed } from "mobx";

import { FLOW_NAMES } from "../../enums/flows";
import { submitDataForFlowApplication } from "../utils/form-submission";
import { BaseFlowStore } from "../base-flow-store";
import get from "lodash.get";

/**
 * TwoStepPrequalFlowStore extends the BaseFlowStore which contains common functionality shared between flows
 */
export class TwoStepPrequalFlowStore extends BaseFlowStore {
   constructor(props) {
      super(props);
      console.debug("TwoStepPrequalFlowStore Initiated");
   }

   get hasCompletedTwoStepPrequalForm() {
      return !!this.applicationUuid;
   }

   /**
    * Redirects to the next step in the flow
    *
    *  - flowStepName: the name of the step to redirect to
    */
   handleStepRedirection = (flowStepName) => {
      let destinationUrl;
      switch (flowStepName) {
         case "coapplicant":
            console.info("stepName: coapplicant - redirecting to coapplicant page");
            this.historyStore.history.push(
               this.getPrequalUrl("/coapplicant") + `?flowUuid=${this.applicationUuid}`
            );
            return;

         case "ssn_required":
            console.info("stepName: SSN - redirecting to SSN page");
            this.historyStore.history.push(
               this.getPrequalUrl(`/ssn-required/${this.applicationUuid}`)
            );
            return;

         case "dealer_selector":
            console.info("stepName: dealer_selector - redirecting to dealer selector");
            destinationUrl = this.getPrequalUrl(`/dealer/${this.applicationUuid}`);
            this.historyStore.history.push(destinationUrl);
            return;

         case "decisioning":
            console.info("stepName: decisioning");
            if (this.isCoapplicantFlow) {
               console.info("stepName: decisioning - coapp");
               destinationUrl = this.getPrequalUrl(
                  `/offers/${this.applicationUuid}/${this.primaryApplicantUuid}`
               );
               this.historyStore.history.push(destinationUrl);
            } else {
               console.info("stepName: decisioning - no coapp");
               destinationUrl = this.getPrequalUrl(`/offers/${this.applicationUuid}`);
               this.historyStore.history.push(destinationUrl);
            }
            return;

         default:
            const metadata = {
               flowStepName,
               applicationUuid: this.applicationUuid,
               primaryApplicantUuid: this.primaryApplicantUuid,
            };
            console.error(
               `Unknown flow step name: ${flowStepName}, has to be implemented in handleStepRedirection`,
               metadata
            );
      }
   };

   getPrequalUrl(subRoute = "") {
      if (
         this.isPartnerWithVehicleIdentifierExperience ||
         this.isPartnerWithVehicleMatchExperience ||
         this.isVehicleSelfSelectionExperience
      ) {
         return "/apply" + subRoute;
      }

      // If there is no vehicle and "dummy vehicle" is enabled, don't add a slug
      if (!this.vehicleConfiguration && this.isDummyVehicleEnabled) {
         return "/apply" + subRoute;
      }

      return `/${this.vehicleConfigurationSlug}/apply` + subRoute;
   }

   submitStepData = async (data, refreshApplicationProgress) => {
      try {
         const response = await submitDataForFlowApplication(FLOW_NAMES.TWO_STEP_PREQUAL, {
            flow_uuid: this.applicationUuid,
            ...data, // custom data for each step
         });
         if (response.success) {
            // refresh application progress
            refreshApplicationProgress && (await this.refreshApplicationProgress());

            const flowStepName = response.data["step_name"];
            this.handleStepRedirection(flowStepName);
         } else {
            const errorMessage = `Server error ${response.status} body: ${response.data}`;
            this.showServerErrorView(errorMessage);
         }
      } catch (error) {
         this.showServerErrorView(error);
      }
   };

   onSubmitDealership = async (dealershipIdentifier) => {
      await this.submitStepData({ dealership_identifier: dealershipIdentifier });
   };

   onSubmitSSN = async (ssn) => {
      const ssnRaw = ssn.replace(/-/g, "");

      const data = {
         flow_uuid: this.applicationUuid,
         step_name: "ssn_required",
         social_security_number: parseInt(ssnRaw),
      };

      await this.submitStepData(data, true);
   };

   // application progress
   refreshApplicationProgress = async () => {
      try {
         const applicationProgressResponse = await this.apiStore.fetch(
            `applications/${this.applicationUuid}/progress`
         );
         if (applicationProgressResponse.status !== 200 || !applicationProgressResponse.response) {
            console.error("Failed to fetch application progress", applicationProgressResponse);
            return null;
         }

         this.updateApplicationProgress({
            applicationProgress: applicationProgressResponse.response,
         });
      } catch (error) {
         console.error("Failed to fetch application progress", error);
         return null;
      }
   };

   updateApplicationProgress({ applicationProgress }) {
      console.debug("updating application progress:", applicationProgress);
      this.applicationProgress = applicationProgress;
      return this;
   }

   get hasSubmittedSSN() {
      return get(this.applicationProgress, "did_submit_social_security_number", false);
   }
}

decorate(TwoStepPrequalFlowStore, {
   hasCompletedTwoStepPrequalForm: computed,
   hasSubmittedSSN: computed,
});
