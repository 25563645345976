import React from 'react';

import {LayoutComponent} from '../layout-component';
import {ReturnHomeCta} from "../cta-footer";
import {noVehicle} from '../svg/graphics';

import copy from './copy.json';


export function VehicleFinancingUnavailable() {
    const {title, text} = copy;
    return (
        <div>
            {/* Layout with svg icon, header and body text */}
            <LayoutComponent
                icon={noVehicle}
                headline={title}
                bodyText={text}
                className="mt-5"
            />
            <ReturnHomeCta />
        </div>
    );
}