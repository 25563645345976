import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import template from "lodash.template";
import {observable} from "mobx"
import {observer} from "mobx-react";
import {Row, Col, Button} from "react-bootstrap";
import ReactMarkdown from "react-markdown";

import {Svg} from "../../svg";
import {formatPrice} from "../../../utils/number-utils";

import copy from './copy.json';


export class OfferTile extends React.Component {
    static propTypes = {
        withoutShadow: PropTypes.bool,
        hideSelectCTA: PropTypes.bool,
        offer: PropTypes.shape({
            loan_amount: PropTypes.number.isRequired,
            term: PropTypes.number.isRequired,
            estimated_monthly_payment: PropTypes.number.isRequired,
            apr: PropTypes.number.isRequired,
            estimated_cash_down: PropTypes.number.isRequired,
            max_amount_financeable: PropTypes.number.isRequired,
        }).isRequired,
        selectedOffer: PropTypes.shape({
            apr: PropTypes.number,
            term: PropTypes.number,
        }),
        onClickSelect: PropTypes.func,
        customCTAComponent: PropTypes.object,
    };

    tileState = observable({
        open: false,
    });

    /**
     * Click handler for additional info toggle button.
     * Hides / Shows the additional info for the offer
     */
    toggleAdditionalInfo = () => (this.tileState.open = !this.tileState.open);

    /**
     * Click handler for select offer button
     * calls onClickSelect prop if passed to component
     */
    onClickSelect = () => {
        if (this.props.onClickSelect) {
            this.props.onClickSelect(
                this.props.offer,
            );
        }
    };

    /**
     * Renders presentation of offer details returned from decisioning
     */
    OfferDetails = () => (
        <Row>
            {/* Left Side (Monthly Payment / Total Financed) */}
            <Col xs={5} md={6}>
                <Row>
                    {/* Monthly Payment */}
                    <Col xs={12} md={{span: 7, order: 0}}>
                        <p className="spark-text-12px">
                            {copy.offer_details.estimated_monthly_payment}
                        </p>
                    </Col>
                    <Col xs={12} md={{span: 7, order: 1}}>
                        <p className="spark-text-20px-bold">
                            {formatPrice(this.props.offer.estimated_monthly_payment, true)}
                        </p>
                    </Col>

                    {/* Total Financed */}
                    <Col xs={12} md={{span: 5, order: 0}}>
                        <p className="spark-text-12px">
                            {copy.offer_details.loan_amount}
                        </p>
                    </Col>
                    <Col xs={12} md={{span: 5, order: 2}}>
                        <p className="spark-text-20px">
                            {formatPrice(Math.round(this.props.offer.loan_amount))}
                        </p>
                    </Col>
                </Row>
            </Col>

            {/* Right Side (Term / APR / Cash Down) */}
            <Col xs={7} md={6}>
                <Row className="mr-0">
                    {/* Term */}
                    <Col xs={6} md={{span: 4, order: 0}} className="p-2 p-md-0 border-right border-bottom border-md-right-0 border-md-bottom-0">
                        <p className="spark-text-12px">
                            {copy.offer_details.term}
                        </p>
                    </Col>
                    <Col xs={6} md={{span: 4, order: 1}} className="p-2 p-md-0 border-bottom border-md-bottom-0">
                        <p className="spark-text-16px">
                            {this.props.offer.term}
                            <span className="spark-text-12px ml-1">
                                {copy.offer_details.term_units}
                            </span>
                        </p>
                    </Col>

                    {/* APR */}
                    <Col xs={6} md={{span: 3, order: 0}} className="p-2 p-md-0 border-right border-bottom border-md-right-0 border-md-bottom-0">
                        <p className="spark-text-12px">
                            {copy.offer_details.apr}
                        </p>
                    </Col>
                    <Col xs={6} md={{span: 3, order: 2}} className="p-2 p-md-0 border-bottom border-md-bottom-0">
                        <p className="spark-text-16px">
                            {this.props.offer.apr}%
                        </p>
                    </Col>

                    {/* Cash Down */}
                    <Col xs={6} md={{span: 5, order: 0}} className="p-2 p-md-0 border-right border-md-right-0">
                        <p className="spark-text-12px">
                            {copy.offer_details.estimated_cash_down}
                        </p>
                    </Col>
                    <Col xs={6} md={{span: 5, order: 3}} className="p-2 p-md-0">
                        <p className="spark-text-16px">
                            {formatPrice(Math.round(this.props.offer.estimated_cash_down), false)} 
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    );

    /**
     * Renders horizontal line
     *  (only visible on larger devices when the tile is open)
     */
    HorizontalLine = observer(({hideSelectCTA}) => (
        <div className={classnames("border-bottom my-3", {
            "d-lg-none": !this.tileState.open,
            "d-none": hideSelectCTA && !this.tileState.open,
        })}/>
    ));

    /**
     * Renders additional info copy about the offer and the max financeable amount
     */
    AdditionalInfo = observer(() => this.tileState.open && (
        <div>
            <div className="spark-bg-moon-grey mx-n3 mb-3 px-3 py-2 text-center text-md-left">
                {/* prefix "Need a little more?" -- only if loan amount is not the max amount */}
                {(this.props.offer.loan_amount < this.props.offer.max_amount_financeable) && (
                    <span className="spark-text-success spark-text-12px spark-text-upper mr-md-2">
                        {copy.additional_info_banner.need_more}
                    </span>
                )}
                <ReactMarkdown
                    className="d-block d-md-inline-block"
                    renderers={{
                        strong: ({children}) => <strong className="spark-text-success">{children}</strong>,
                    }}
                >
                    {template(copy.additional_info_banner.approved_for)({
                        amount: formatPrice(Math.round(this.props.offer.max_amount_financeable)),
                    })}
                </ReactMarkdown>
            </div>
            <ReactMarkdown
                renderers={{
                    heading: props => (props.level === 6 ? (
                        <h6 className="spark-text-14px">{props.children}</h6>
                    ) : ReactMarkdown.renderers.heading(props)),
                    paragraph: props => <p className="mb-3">{props.children}</p>,
                }}
            >
                {copy.additional_info_text}
            </ReactMarkdown>
        </div>
    ));

    /**
     * Renders hide / show link to hide and show the additional info
     */
    AdditionalInfoToggle = observer(({hideSelectCTA}) => (
        <div className={classnames("text-lg-right", {"mt-lg-n4": hideSelectCTA && !this.tileState.open})}>
            <Button size="sm" variant="link" className="px-0 pb-2 pb-lg-0 mt-md-1 mt-lg-n2 spark-text-14px" onClick={this.toggleAdditionalInfo}>
                {this.tileState.open ? copy.toggle.hide : copy.toggle.show}
            </Button>
        </div>
    ));

    /**
     * Renders the select CTA (or custom cta if passed through props)
     */
    SelectCTA = ({hideSelectCTA, selectedOffer}) => {
        const {offer} = this.props;
        if (hideSelectCTA) {
            return null;
        }
        if (this.props.customCTAComponent) {
            return this.props.customCTAComponent;
        }
        if (selectedOffer && (offer.apr === selectedOffer.apr) && offer.term === selectedOffer.term) {
            return (
                <Button block variant="success" className="mt-n1 mt-lg-0" onClick={this.onClickSelect}>
                    <Svg style={{height: ".75rem"}} className="mr-1" svg="check"/>
                    {copy.selected_cta}
                </Button>
            );
        }
        return (
            <Button block variant="primary" className="mt-n1 mt-lg-0" onClick={this.onClickSelect}>
                {copy.select_cta}
            </Button>
        );
    };

    render() {
        return (
            <div className={classnames({"spark-shadow-tile rounded p-3": !this.props.withoutShadow})}>
                <Row className="align-items-center align-items-lg-start">
                    <Col xs={12} lg={{span: 9, order: 0}}>
                        <this.OfferDetails/>
                    </Col>
                    <Col xs={12} lg={{span: 12, order: 2}}>
                        <this.HorizontalLine
                            hideSelectCTA={this.props.hideSelectCTA}
                        />
                    </Col>
                    <Col xs={12} lg={{span: 12, order: 3}}>
                        <this.AdditionalInfo/>
                    </Col>
                    <Col xs={5} lg={{span: 3, order: 4, offset: 9}}>
                        <this.AdditionalInfoToggle
                            hideSelectCTA={this.props.hideSelectCTA}
                        />
                    </Col>
                    <Col xs={7} lg={{span: 3, order: 1}}>
                        <this.SelectCTA
                            hideSelectCTA={this.props.hideSelectCTA}
                            selectedOffer={this.props.selectedOffer}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
