import {validateVehiclePayload, parseRawVehicleDataFromQueryParams} from "./vehicle";

let receivedWidgetMessage = false;

/*
* Adds event listener to handle postMessage from the dealer cta widget
* If no widget message is received within 6s, stops searching and runs onWidgetTimeout callback.
*/
export const startWidgetMessageListener = (window, stores, onWidgetTimeout, onWidgetSuccess) => {

    console.debug("Registering widget listener");
    window.addEventListener("message", (event) => {widgetMessageHandler(event, stores, onWidgetSuccess)});

    let timeoutCounter = 0;
    const maxAttempts = 20;
    const searchInterval = 300;

    const searchIntervalId = setInterval(() => {
        if(receivedWidgetMessage){
            clearInterval(searchIntervalId);
        }else{
            if(timeoutCounter>=maxAttempts){
                console.error("Timed out waiting for vehicle message");
                clearInterval(searchIntervalId);
                onWidgetTimeout && onWidgetTimeout();
            }
            timeoutCounter++;
        }
    }, searchInterval);
}

/**
 * Message handler for dealer cta widget
 * The handler takes in a vehicle payload, sets the dealership, and fetches the vehicle configuration
 * To guard against malicious messages, we have to validate the message's origin and data first
 * @param {MessageEvent<any>} event
 * @param {BaseStore} stores
 * @param {function():void} onWidgetSuccess
 * @return {Promise<void>}
 * @throws Error if missing partnerStore, dealershipStore, or historyStore
 */
export const widgetMessageHandler = async (event, stores, onWidgetSuccess) => {
    if(stores && !stores.partnerStore){
        throw new Error("widgetMessageHandler - Missing param: store.partnerStore");
    }

    if(stores && !stores.dealershipStore){
        throw new Error("widgetMessageHandler - Missing param: store.dealershipStore");
    }

    if(stores && !stores.historyStore){
        throw new Error("widgetMessageHandler - Missing param: store.historyStore");
    }

    console.debug("Message Received", event); // Used to debug on prod. To be removed in the future

    const isValidVehicleMessage = validateVehiclePayload(event.data);
    if (!isValidVehicleMessage) {
        return;
    }
    if (receivedWidgetMessage && stores.partnerStore.rawVehicle === event.data) {
        // duplicate message
        return;
    }

    console.debug("Vehicle Message received From Widget", event.data);
    const isTrusted = await stores.dealershipStore.isTrustedUrl(event.origin);
    if (!isTrusted) {
        return; // Message origin did not match any dealerships
    }

    try{
        stores.partnerStore.rawVehicle = event.data;
        if (event.data.product_origin_url) {
            stores.partnerStore.partnerOriginUrl = event.data.product_origin_url;
        }
        if (event.data.product_id) {
            stores.partnerStore.productId = event.data.product_id;
        }
        receivedWidgetMessage = true;

        const match_params = {
            make: event.data.product_make,
            model: event.data.product_model,
            year: event.data.product_year,
            price: event.data.product_price,
            color: event.data.product_color,
            condition: event.data.product_condition,
        };

        // override data based on flag rawVehicleData
        if (stores.historyStore.queryParams.rawVehicleData === '1') {
            const rawVehicleData = parseRawVehicleDataFromQueryParams(stores.historyStore.queryParams);
            if (!rawVehicleData) return;

            match_params.make = rawVehicleData.raw_make;
            match_params.model = rawVehicleData.raw_model;
            match_params.year = rawVehicleData.raw_year;
            match_params.price = rawVehicleData.raw_price;
            match_params.condition = rawVehicleData.raw_condition;

            Object.assign(stores.partnerStore.rawVehicle, {
                product_category: rawVehicleData.raw_type,
                product_condition: rawVehicleData.raw_condition,
                product_make: rawVehicleData.raw_make,
                product_model: rawVehicleData.raw_model,
                product_year: rawVehicleData.raw_year,
                product_price: rawVehicleData.raw_price,
            });
            console.debug('[rawVehicleData] using raw vehicle query params:', rawVehicleData);
        }

        if (stores.partnerStore.dealerRoutingStrategy === 'WidgetDealerRoutingStrategy') {
            await stores.dealershipStore.fetchDealerGroupRouting(stores.partnerStore.partnerIdentifier, stores.partnerStore.rawVehicle.product_location, event.origin);
        }

        console.debug('[widgetMessageHandler] match_params:', match_params);

        await stores.partnerStore.loadPartnerVehicleMatch(match_params);

        onWidgetSuccess && onWidgetSuccess();
    } catch (error) {
        console.log(`Error handling vehicle message from ${event.origin}`, event.data);
        console.log(error);
    }
};
