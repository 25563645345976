import { decorate, observable, computed, action } from "mobx";
import get from "lodash.get";
import template from "lodash.template";

import copy from "./copy";
import { submitDataForFlowApplication } from "../../utils/form-submission";
import { initialFieldStates, testApplicantFieldStates } from "./field-states";
import {
   formatFormData,
   addPartnerSpecificApplicationData,
   formatVehicleData,
} from "../../utils/submission-helpers";
import { validHeaderTypes } from "../../../components/form-header/form-header";
import { isWindowDefined } from "../../../utils/general-utils";
import { FLOW_NAMES } from "../../../enums/flows";

export class AboutYouStepStore {
   getFieldValue = (fieldName, defaultValue) => this.fieldStates[fieldName]?.value ?? defaultValue;

   resetInitialFieldStates = () => {
      this.fieldStates = initialFieldStates;
      this.textConsent = null;
      this.prequalDisclosureConsent = null;
   };

   updateFieldValue = (value, fieldName) => {
      this.fieldStates[fieldName] = value;
   };

   replaceFieldValues(values) {
      Object.keys(values).forEach((fieldName) => {
         if (this.fieldStates[fieldName]) {
            this.fieldStates[fieldName].value = values[fieldName];
         }
      });
   }

   submitForm = async (
      captcha,
      vehicleConfiguration,
      rawVehicleData,
      updateApplicationUuid,
      updateApplicantUuids
   ) => {
      // Avoids error where the form is submitted twice on local environment
      if (this.rideOctaneStore.settings.ENVIRONMENT_KEY === "local" && captcha === null) {
         console.error("Captcha is null. Avoiding re-submission.");
         return;
      }

      let data = formatFormData(this.fieldStates);
      data.captcha = captcha;
      data.meta_data = {};

      if (this.historyStore.queryParams.dealership) {
         data.consumer_dealership_identifier = this.historyStore.queryParams.dealership;
         data.dealership = this.historyStore.queryParams.dealership;
      }

      if (this.historyStore.queryParams.a) {
         data.meta_data.assigned_link = this.historyStore.queryParams.a;
      }

      if (this.dealershipStore.externalDealershipId) {
         data.external_dealership_id = this.dealershipStore.externalDealershipId;
      }

      if (this.themeStore.theme) {
         data.meta_data.appTheme = this.themeStore.theme;
      }

      if (this.partnerStore) {
         data = addPartnerSpecificApplicationData(data, this.partnerStore);
      }

      if (vehicleConfiguration) {
         data.vehicle = vehicleConfiguration.uuid;
      }

      if (rawVehicleData) {
         data.vehicle_data = formatVehicleData(rawVehicleData, "vss");
      } else if (this.partnerStore.rawVehicle) {
         data.vehicle_data = formatVehicleData(this.partnerStore.rawVehicle, "widget");
      }

      const flowUuid = this.historyStore.queryParams.flowUuid;
      if (flowUuid) data.flow_uuid = flowUuid;

      // save zip code to user store so that it can be used by dealer selector
      this.userStore.updateUserInfoField("zip_code", data.zip_code);

      const queryParams = this.historyStore.queryParams;
      if (queryParams.inStore || queryParams.instore) {
         data.meta_data.inStore = true;
      }

      /* If coapplicant intent is enabled and the coapplicant intent field is set */
      if (
         !this.flowStore.isCoapplicantFlow &&
         this.partnerStore?.partnerStore?.isCoapplicantEnabled &&
         data.coapplicant_intent
      ) {
         /* Saves address data to be reused by coapplicant */
         this.primaryApplicantAddressData = {
            street1: data.street1,
            street2: data.street2,
            city: data.city,
            state: data.state,
            zip_code: data.zip_code,
         };
         this.resetInitialFieldStates();
      }

      try {
         const response = await submitDataForFlowApplication(FLOW_NAMES.TWO_STEP_PREQUAL, data);
         if (response.success) {
            const applicationUuid = response.data["flow_uuid"];
            const primaryApplicantUuid = response.data["primary_applicant_uuid"];
            const flowStepName = response.data["step_name"];

            updateApplicationUuid(applicationUuid);
            updateApplicantUuids({ primaryApplicantUuid });
            this.flowStore.handleStepRedirection(flowStepName);
            this.resetInitialFieldStates();
         } else {
            const errorMessage = `Server error ${response.status} body: ${response.data}`;
            this.showServerErrorView(errorMessage);
         }
      } catch (error) {
         this.showServerErrorView(error);
      }
   };

   showServerErrorView(message) {
      this.serverErrorMessage = message;

      // This must be the last statement to avoid multiple rendering of the ServerError view
      this.showServerError = true;
   }

   forceFieldsValidation = () => {
      this.getFieldNames.forEach((fieldName) => {
         const field = document.getElementById(fieldName);
         const isSelect = field && field.dataset && field.dataset["oid"] === "select";
         if (isSelect) {
            // Select fields doesn't trigger blur events, so we validate required manually
            this.fieldStates[fieldName].error = !this.fieldStates[fieldName].value;
         } else {
            // For other types, trigger automatic validation by forcing blur event
            field && field.focus({ preventScroll: true });
            field && field.blur();
         }
      });
   };

   onReusePrimaryAddressChange(state) {
      this.updateFieldValue(state, "reuse_primary_address");
      if (this.primaryApplicantAddressData) {
         this.replaceFieldValues(this.primaryApplicantAddressData);
      }
   }

   updatePrequalDisclosureConsent = (prequalDisclosureConsent) => {
      this.prequalDisclosureConsent = prequalDisclosureConsent;
      this.forceFieldsValidation();
   };

   updateTextConsent = (textConsent) => {
      this.textConsent = textConsent;
   };

   get getFieldNames() {
      return Object.keys(this.fieldStates);
   }

   get isFormValid() {
      return this.getFieldNames.reduce(
         (agg, fieldName) => agg && !this.fieldStates[fieldName].error,
         true
      );
   }

   get headerType() {
      return this.partnerStore.partner
         ? validHeaderTypes.find(
              (header) => header === this.partnerStore.partner.about_you_header
           ) || null
         : validHeaderTypes[0];
   }

   get continueButtonLabel() {
      const copyKey = this.getFieldValue("coapplicant_intent", false)
         ? "continue_button.add_coapplicant_label"
         : "continue_button.label";
      return this.uxCopyStore.getAboutYouCopy(copyKey) || get(copy, copyKey, "");
   }

   get showPoweredByOctane() {
      return get(this.partnerStore, "partner.show_powered_by_octane") !== false;
   }

   get showVehicleFinancingUnavailable() {
      return !(this.partnerStore.partner && this.partnerStore.partner.is_brp);
   }

   get showCoapplicantIntentField() {
      return !this.flowStore.isCoapplicantFlow && this.partnerStore.isCoapplicantEnabled;
   }

   get isReusingPrimaryAddress() {
      return this.fieldStates["reuse_primary_address"]?.value || false;
   }

   prequalDisclosureConsent = false;
   /** Note: Name and privacy policy URL must be filled out at the partner and/or dealership level to add additional policy */
   get prequalDisclosure() {
      const dealership = this.dealershipStore.dealership;
      const partner = this.partnerStore.partner;
      let name, privacyPolicyURL;

      /* For Reveo we don't want to show either partner or dealer privacy policy as it's in their experience */
      if (!this.partnerStore.isReveo) {
         // if dealership is populated and it has privacy_policy_url set, use it to construct the disclosure
         if (dealership && dealership.name && dealership.privacy_policy_url) {
            name = dealership.name;
            privacyPolicyURL = dealership.privacy_policy_url;
         }
         // if partner is populated and it has privacy_policy_url set, use it to construct the disclosure
         else if (partner && partner.name && partner.privacy_policy_url) {
            name = partner.name;
            privacyPolicyURL = partner.privacy_policy_url;
         }
      }

      // if enable_alternate_lenders enabled, need to render markdown with alternate lenders disclosure
      if (partner && get(partner, "enable_alternate_lenders")) {
         return template(copy.prequal_disclosure.alternate_lenders_markdown_content)({
            continueButtonLabel: this.continueButtonLabel,
            name,
            privacyPolicyURL,
         });
      }

      return template(copy.prequal_disclosure.markdown_content)({
         continueButtonLabel: this.continueButtonLabel,
         name,
         privacyPolicyURL,
      });
   }

   constructor({
      apiStore,
      historyStore,
      themeStore,
      uxCopyStore,
      dealershipStore,
      partnerStore,
      rideOctaneStore,
      flowStore,
      waffleStore,
      userStore,
      ...props
   }) {
      this.apiStore = apiStore;
      this.uxCopyStore = uxCopyStore;
      this.dealershipStore = dealershipStore;
      this.partnerStore = partnerStore;
      this.historyStore = historyStore;
      this.themeStore = themeStore;
      this.rideOctaneStore = rideOctaneStore;
      this.flowStore = flowStore;
      this.waffleStore = waffleStore;
      this.userStore = userStore;

      this.textConsent = false;
      this.prequalDisclosureConsent = false;
      this.primaryApplicantAddressData = null;

      this.showServerError = false;
      this.serverErrorMessage = "";

      // if a testApplicant is passed on query params, fills form
      const isDev = rideOctaneStore.settings.ENVIRONMENT_KEY !== "production";
      const testApplicant = this.historyStore.queryParams.testApplicant;
      this.fieldStates =
         testApplicant && isDev ? testApplicantFieldStates[testApplicant] : initialFieldStates;

      if (isWindowDefined()) {
         window.replaceFieldValues = (values) => this.replaceFieldValues(values);
      }
   }
}

decorate(AboutYouStepStore, {
   fieldStates: observable,
   prequalDisclosureConsent: observable,
   textConsent: observable,
   showServerError: observable,
   serverErrorMessage: observable,
   primaryApplicantAddressData: observable,

   getFieldNames: computed,
   isFormValid: computed,
   headerType: computed,
   showVehicleFinancingUnavailable: computed,
   showPoweredByOctane: computed,
   showCoapplicantIntentField: computed,
   continueButtonLabel: computed,
   prequalDisclosure: computed,

   updateFieldValue: action,
   forceFieldsValidation: action,
   onReusePrimaryAddressChange: action,
   updatePrequalDisclosureConsent: action,
   updateTextConsent: action,
   resetInitialFieldStates: action,
});
