import React from 'react';
import PropTypes from "prop-types";

import copy from "../copy";
import {citizenshipStatuses} from "../../enums/citizenship_status";
import {ListDropdown} from "../../components/dropdown";

const citizenshipStatusItems = citizenshipStatuses.map(({id, name}) => ({key: id, label: name}));

/**
 * Reusable component for rendering citizen status form field
 *
 * @param {string}                 value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 controlId - input control id
 */
export function CitizenshipStatusField({onChange, required = true, ...props}) {
    const handleChange = (event) => {
        onChange({value: event.key, error: required && !event.key});
    }
    return (
        <ListDropdown
            {...copy.citizenship_status}
            items={citizenshipStatusItems}
            onChange={handleChange}
            {...props}
        />
    );
}

CitizenshipStatusField.propTypes = {
    value: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    controlId: PropTypes.string, 
};
