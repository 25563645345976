import React from "react";
import {inject, observer} from "mobx-react";
import {Redirect, Route, Switch} from 'react-router-dom';

import {BrpGaPixel} from "../components/brp-ga-pixel";
import {withRideOctaneTitle, PageTitles} from "../pages/octane-title";

import {PrequalStore} from "./prequal-store";
import {AboutYou} from "./about-you";
import {DealerSelector} from './dealer-selector';
import {SSNRequired} from "./ssn-required";
import {Offers} from "./offers";

export const Prequal = inject('rideOctaneStore', 'apiStore', 'userStore', 'uxCopyStore', 'themeStore', 'partnerStore', 'historyStore', 'dealershipStore', 'vehicleSelfSelectionStore', 'waffleStore', 'dealerSelectorStore')(
    observer(
        class _Prequal extends React.Component {
            prequalStore = new PrequalStore(this.props);
            componentDidMount() {
                // Only start the widgetMessageHandler for dealer widget experirence since
                // other experience don't need it
                // If widget is cached and partner vehicle match parameter exists, consider that
                if (this.prequalStore.isPartnerDealerWidgetExperience && !this.prequalStore.isVehicleSelfSelectionExperience) {
                    this.prequalStore.startWidgetMessageListener(window);
                } else {
                    this.prequalStore.initializeVehicleConfiguration();
                }

                if(this.prequalStore.applicationUuid) {
                    this.prequalStore.refreshApplicationProgress();
                }
            }

            render() {
                // for <title/> in head
                const titleWords = [];
                if (this.prequalStore.displayVehicle) {
                    titleWords.push(this.prequalStore.displayVehicle.name);
                }

                return (
                    <>
                        {/* invisible pixel BRP uses to track customers */}
                        <BrpGaPixel
                            vehicle_configuration={this.prequalStore.vehicleConfiguration}
                        />
                        <Switch>
                            <Route exact path="/:slug?/apply">
                                {withRideOctaneTitle(
                                    <AboutYou
                                        hasFetchedVehicle={this.prequalStore.hasFetchedVehicle}
                                        vehicleConfiguration={this.prequalStore.displayVehicle}
                                        vssConfiguration={this.prequalStore.rawVehicleData}
                                        isDummyVehicleEnabled={this.prequalStore.isDummyVehicleEnabled}
                                        onClickContinue={this.prequalStore.onSubmitAboutYouForm}
                                        onClickContinueToCoapplicant={this.prequalStore.onContinueToCoapplicant}
                                        widgetError={this.prequalStore.widgetError}
                                        isCoapplicantFlow={this.prequalStore.isCoapplicantFlow}
                                        fieldNamePrefix={""}
                                    />,
                                    [...titleWords, PageTitles.ABOUT_YOU_TITLE]
                                )}
                            </Route>
                            <Route exact path="/:slug?/apply/coapplicant">
                                {withRideOctaneTitle(
                                    <AboutYou
                                        hasFetchedVehicle={this.prequalStore.hasFetchedVehicle}
                                        vehicleConfiguration={this.prequalStore.displayVehicle}
                                        vssConfiguration={this.prequalStore.rawVehicleData}
                                        isDummyVehicleEnabled={this.prequalStore.isDummyVehicleEnabled}
                                        onClickContinue={this.prequalStore.onSubmitAboutYouForm}
                                        onClickContinueToCoapplicant={this.prequalStore.onContinueToCoapplicant}
                                        widgetError={this.prequalStore.widgetError}
                                        isCoapplicantFlow={this.prequalStore.isCoapplicantFlow}
                                        fieldNamePrefix={"coapplicant_"}
                                    />,
                                    [...titleWords, PageTitles.COAPPLICANT_ABOUT_YOU_TITLE]
                                )}
                            </Route>
                            <Route exact path="/:slug?/apply/ssn-required/:appUuid">
                                {withRideOctaneTitle(
                                    <SSNRequired
                                        hasCompletedAboutYouForm={this.prequalStore.hasCompletedAboutYouForm}
                                        onSubmitSSN={this.prequalStore.onSubmitSSN}
                                        offersUrl={this.prequalStore.getPrequalUrl(`/offers/${this.prequalStore.applicationUuid}`)}
                                        aboutYouPrequalUrl={this.prequalStore.getPrequalUrl()}
                                        applicationUuid={this.prequalStore.applicationUuid}
                                        hasSubmittedSSN={this.prequalStore.hasSubmittedSSN}
                                    />,
                                    [...titleWords, PageTitles.SSN_REQUIRED_TITLE]
                                )}
                            </Route>
                            <Route exact path="/:slug?/apply/dealer">
                                {withRideOctaneTitle(
                                    <DealerSelector
                                        zipCodeUser={this.props.userStore.userInfo['zip_code']}
                                        onSubmitDealership={this.prequalStore.onSubmitDealership}
                                    />,
                                    [...titleWords, PageTitles.DEALER_SELECTOR]
                                )}
                            </Route>
                            <Route exact path="/:slug?/apply/offers/:appUuid">
                                {withRideOctaneTitle(
                                    <Offers
                                        hasCompletedAboutYouForm={this.prequalStore.hasCompletedAboutYouForm}
                                        aboutYouPrequalUrl={this.prequalStore.getPrequalUrl()}
                                        applicationUuid={this.prequalStore.applicationUuid}
                                        applicantUuid={this.prequalStore.primaryApplicantUuid}
                                        accessoriesAmount={this.prequalStore.accessoriesAmount}
                                        vehicleConfiguration={this.prequalStore.displayVehicle}
                                        vssConfiguration={this.prequalStore.rawVehicleData}
                                        hasSubmittedSSN={this.prequalStore.hasSubmittedSSN}
                                        isApplicantCMPFlow={false}
                                        ssnRequiredUrl={this.prequalStore.getPrequalUrl(`/ssn-required/${this.prequalStore.applicationUuid}`)}
                                    />,
                                    [...titleWords, PageTitles.OFFERS_TITLE]
                                )}
                            </Route>
                            <Route exact path="/:slug?/apply/offers/:appUuid/:applicantUuid">
                                {withRideOctaneTitle(
                                    <Offers
                                        hasCompletedAboutYouForm={this.prequalStore.hasCompletedAboutYouForm}
                                        aboutYouPrequalUrl={this.prequalStore.getPrequalUrl()}
                                        applicationUuid={this.prequalStore.applicationUuid}
                                        applicantUuid={this.prequalStore.primaryApplicantUuid}
                                        accessoriesAmount={this.prequalStore.accessoriesAmount}
                                        vehicleConfiguration={this.prequalStore.displayVehicle}
                                        vssConfiguration={this.prequalStore.rawVehicleData}
                                        hasSubmittedSSN={this.prequalStore.hasSubmittedSSN}
                                        isApplicantCMPFlow={true}
                                        ssnRequiredUrl={this.prequalStore.getPrequalUrl(`/ssn-required/${this.prequalStore.applicationUuid}`)}
                                    />,
                                    [...titleWords, PageTitles.OFFERS_TITLE]
                                )}
                            </Route>
                            <Route>
                                <Redirect to={this.prequalStore.getPrequalUrl()}/>
                            </Route>
                        </Switch>
                    </>
                );
            }
        }
    )
);
