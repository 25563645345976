import React from "react";
import PropTypes from "prop-types";
import {Input} from "../input";
import {observer} from "mobx-react";
import {DateInputStore, MonthAndYearInputStore} from "./date-input-store";
import {DateInputType} from "../../utils/date-utils";

export const DateInput = observer(
    class _DateInput extends React.Component {
        static propTypes = {
            label: PropTypes.string,
            success: PropTypes.bool,
            error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
            value: PropTypes.oneOfType([
                PropTypes.bool, 
                PropTypes.shape({
                    month: PropTypes.number.isRequired,
                    day: PropTypes.number,
                    year: PropTypes.number.isRequired
                }
            )]),
            inputType: PropTypes.string.isRequired,
            validator: PropTypes.func,
            required: PropTypes.bool,
            placeholder: PropTypes.string,
            // event handler
            onChange: PropTypes.func
        };

        constructor(props, context) {
            super(props, context);

            this.cursorIndex = 0;
            this.hasHadFirstBlur = false;
            this.placeholder = props.placeholder ?? props.inputType ?? "";

            switch(props.inputType) {
                case DateInputType.FULL_DATE:
                    this.store = new DateInputStore(this.props.value);
                    break;
                case DateInputType.MONTH_AND_YEAR:
                    this.store = new MonthAndYearInputStore(this.props.value);
                    break;
                default:
                    this.store = new DateInputStore(this.props.value);
            }
        }

        setCursorIndex = () => {
            this.target &&
                document.activeElement === this.target &&
                this.target.setSelectionRange(
                    this.cursorIndex,
                    this.cursorIndex
                );
        };

        doDatesMatch = (dateOne, dateTwo) => {
            if (!dateOne || !dateTwo) {
                return dateOne === dateTwo;
            }
            return (
                dateOne.month === dateTwo.month &&
                dateOne.day === dateTwo.day &&
                dateOne.year === dateTwo.year
            );
        };

        componentDidUpdate(prevProps) {
            this.setCursorIndex();

            if (
                !this.doDatesMatch(this.props.value, prevProps.value) &&
                !this.doDatesMatch(this.store.getDate(), this.props.value)
            ) {
                this.store.updateValue(this.props.value);
            }
        }

        /**
            The date should only be updated by prop event handlers if
                - there is not a prop value OR
                - the current date in the store doesn't match the prop value 
            Note:
                This prevents running into a loop of calling onChange to 
                update the value and that update triggering a new change event
        */
        shouldUpdateDate = () => !this.props.value || !this.doDatesMatch(this.props.value, this.store.getDate());

        hasValidationError = (newValue) => {
            const isEmptyField = !String(newValue).replace(/[/_\s]/gm, ''); // Removes masking characters from the value and checks if it is empty
            return this.hasHadFirstBlur && this.props.validator && !this.props.validator(this.store.getDate()) && !isEmptyField;
        }

        hasRequiredError = () => this.hasHadFirstBlur && !!this.props.required && !this.store.getDate();

        handleBlur = (event) => {
            this.hasHadFirstBlur = true;
            this.shouldUpdateDate() && this.props.onBlur && this.props.onBlur(event, this.store.getDate(), this.hasValidationError(event.target.value) || this.hasRequiredError());
        };

        handleChange = (event) => {
            event.preventDefault();
            const {target} = event;
            const {value} = target;

            if (!this.target) this.target = target; // Setting target to be used to set the cursor

            const {formatted, nextIndex} = this.store.formatInput({
                currentValue: value,
                previousValue: this.store.value,
                currentIndex: this.target.selectionStart,
                previousIndex: this.cursorIndex
            });
            this.cursorIndex = nextIndex;
            this.store.updateValue(formatted);

            /* To prevent cursor jump on input that doesn't trigger a re-render */
            if (formatted === this.store.value) {
                setTimeout(() => {
                    this.setCursorIndex();
                }, 0);
            }

            /* If there is an onChange prop, call it here */
            if (this.props.onChange && this.shouldUpdateDate()) {
                this.props.onChange(event, this.store.getDate(), this.hasValidationError(value) || this.hasRequiredError());
            }
        };

        handleClick = ({target}) => {
            /* If the user clicked on the input, capture the new cursor index */
            this.cursorIndex = target.selectionStart;
        };

        handleKeyUp = (event) => {
            const {target, nativeEvent} = event;
            const {code} = nativeEvent;

            /* If the user is moving left or right using their keyboard, capture the new cursor index */
            if (code === "ArrowLeft" || code === "ArrowRight") {
                this.cursorIndex = target.selectionStart;
            }
        };

        render() {
            return (
                <Input
                    {...this.props}
                    value={this.store.value}
                    type="text"
                    placeholder={this.placeholder}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onClick={this.handleClick}
                    onKeyUp={this.handleKeyUp}
                    inputMode="numeric"
                    autoValidateAndFormat={false}
                />
            );
        }
    }
);
