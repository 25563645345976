import React from 'react';
import PropTypes from "prop-types";

import copy from "../copy";
import {DateInput} from "../../components/date-input";
import {DateInputType} from "../../utils/date-utils";
import {validateDateOfBirth} from "../../utils/validation";

/**
 * Reusable component for rendering date of birth form field
 *
 * @param {boolean|object}         value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 controlId - input control id
 */
export function DateOfBirthField({onChange, ...props}) {
    const changeHandler = (e, value, error) => {
        onChange({value, error})
    }

    return (
        <DateInput
            {...copy.date_of_birth}
            inputType={DateInputType.FULL_DATE}
            validator={validateDateOfBirth}
            required
            onChange={changeHandler}
            onBlur={changeHandler}
            {...props}
        />
    );
}

DateOfBirthField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    controlId: PropTypes.string, 
};
