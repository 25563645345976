import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Container } from "react-bootstrap";

import { ServerError } from "../../../components/interstitials";
import { FancySpinner } from "../../../components/spinners/fancy-spinner";
import { Redirect } from "react-router-dom";
import { logVehicleFinancingUnavailable } from "../../../prequal/about-you/about-you-helpers";
import { VehicleFinancingUnavailable } from "../../../components/vehicle-financing-unavailable";

import { AboutYouStepStore } from "./about-you-step-store";
import AboutYouStep from "./about-you-step";
import { isInIFrame, postMessageScrollIntoView } from "../../../utils/iframe-utils";

export const AboutYouStepContainer = inject(
   "rideOctaneStore",
   "apiStore",
   "userStore",
   "uxCopyStore",
   "themeStore",
   "partnerStore",
   "historyStore",
   "dealershipStore",
   "waffleStore",
   "dealerSelectorStore"
)(
   observer(
      class _AboutYouStep extends React.Component {
         static propTypes = {
            accessoriesAmount: PropTypes.number,
            hasFetchedVehicle: PropTypes.bool,
            vehicleConfiguration: PropTypes.shape({
               name: PropTypes.string.isRequired,
               msrp: PropTypes.number.isRequired,
               default_image: PropTypes.string.isRequired,
            }),
            isDummyVehicleEnabled: PropTypes.bool.isRequired,
            rawVehicleData: PropTypes.object,
            isCoapplicantFlow: PropTypes.bool,
         };

         constructor(props, context) {
            super(props, context);
            this.store = new AboutYouStepStore(props);
            this.captchaRef = React.createRef();
         }

         componentDidMount() {
            window.scrollTo(0, 0);
            if (this.captchaRef && this.captchaRef.current) {
               this.captchaRef.current.reset();
            }
         }

         launchCaptcha = () => {
            if (isInIFrame()) {
               postMessageScrollIntoView(window.top);
            } else {
               window.scrollTo(0, 0);
            }
            this.captchaRef.current.execute();
         };

         render() {
            if (this.store.showServerError) {
               return (
                  <Container data-oid="two-step-prequal-error-page">
                     <ServerError
                        origin="two-step-prequal"
                        errorMessage={this.store.serverErrorMessage}
                     />
                  </Container>
               );
            }

            // If haven't fetched the vehicle and we need the vehicle for the VehicleHeader display the fancy loading spinner
            if (this.store.headerType === "VehicleHeader" && !this.props.hasFetchedVehicle) {
               return (
                  <Container data-oid="two-step-prequal-loading">
                     <FancySpinner className="d-block mx-auto my-5" />
                  </Container>
               );
            }

            // If the vehicle has been fetched and there is no vehicle configuration and this partner doesn't decision on dummmy vehicles
            if (
               this.props.hasFetchedVehicle &&
               !this.props.vehicleConfiguration &&
               !this.props.isDummyVehicleEnabled
            ) {
               // For certain partners (BRP), to give the customer a better user experience, we don't render the
               // vehicle not found view and instead just redirect them to the homepage to select the vehicle through us
               if (!this.store.showVehicleFinancingUnavailable) {
                  return <Redirect to="/" />;
               }
               logVehicleFinancingUnavailable(
                  this.props.partnerStore.partnerIdentifier,
                  this.props.hasFetchedVehicle,
                  this.props.vehicleConfiguration,
                  this.props.isDummyVehicleEnabled
               );
               return (
                  <Container data-oid="two-step-prequal-unavailable">
                     <VehicleFinancingUnavailable />
                  </Container>
               );
            }

            return (
               <AboutYouStep
                  isCoapplicantFlow={this.props.isCoapplicantFlow}
                  accessoriesAmount={this.props.accessoriesAmount}
                  vehicleConfiguration={this.props.vehicleConfiguration}
                  rawVehicleData={this.props.rawVehicleData}
                  hasFetchedVehicle={this.props.hasFetchedVehicle}
                  // stores
                  store={this.store}
                  flowStore={this.props.flowStore}
                  partnerStore={this.props.partnerStore}
                  // captcha
                  captchaRef={this.captchaRef}
                  launchCaptcha={this.launchCaptcha}
               />
            );
         }
      }
   )
);
