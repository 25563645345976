import React from 'react';
import PropTypes from "prop-types";

import copy from "../copy";
import {relationshipToPrimary} from '../../enums/relationshipToPrimary';
import {ListDropdown} from "../../components/dropdown";

const relationshipItems = relationshipToPrimary.map(({id, name}) => ({key: id, label: name}));

/**
 * Reusable component for rendering relationship to primary form field
 *
 * @param {string}                 value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 controlId - input control id
 */
export function RelationshipToPrimaryField({onChange, ...props}) {
    const handleChange = (event) => {
        onChange({value: event.key, error: false});
    }
    return (
        <ListDropdown
            {...copy.relationship_to_primary}
            items={relationshipItems}
            onChange={handleChange}
            {...props}
        />
    );
}

RelationshipToPrimaryField.propTypes = {
    value: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    controlId: PropTypes.string, 
};
