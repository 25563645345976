import React from 'react';
import classNames from 'classnames';

import {Svg} from "../svg";
import {shield} from "../svg/visual-icons";

import styles from "./branding.module.scss";
import copy from "./copy.json";


/**
 * Our branded value props with the Powered by Octane
 * @param {boolean} showPoweredByOctane - Determines whether or not the Powered By Octane component is shown
 * @param {boolean} showDesktopLayout - Determines whether or not the desktop classes are added to the component
 * @param {boolean} showBrandingForCampingWorld - Determines whether or not the branding component for CW is shown
 * @param {boolean} showMiniProp - Determines whether or not the MiniProp component is shown
 *
 * ***Note**: Desktop layout on About You is currently limited to multi-column layout
 * so the desktop layout of this component should only be shown when isReveo or isRV is true.
 * when showDesktopLayout is false, PoweredByOctane will be centered and have the MiniProp stacked on top of it if shown
 * When desktop layout on About You is added for all experiences, showDesktopLayout should be removed.*
 * */
export const BrandingValueProp = ({showPoweredByOctane, showDesktopLayout, showBrandingForCampingWorld, showMiniProp}) => {
    const containerClasses = classNames(
        "spark-text-secondary spark-text-12px my-4 text-center",
        {[styles.desktopContainer]: showDesktopLayout},
    );
    const poweredByOctaneClasses = classNames({
        [styles.desktopPoweredByOctane]: showDesktopLayout && !showBrandingForCampingWorld,
        [styles.desktopCampingWorld]: showDesktopLayout && showBrandingForCampingWorld && !showMiniProp,
        [styles.desktopCampingWorldMiniProp]: showDesktopLayout && showBrandingForCampingWorld && showMiniProp,
    });
    const poweredByOctaneContent = showBrandingForCampingWorld ? (
        copy.camping_world
    ) : (
        copy.powered_by
    );

    return (
        <div className={containerClasses}>
            {!!showMiniProp && <MiniProp />}
            {showPoweredByOctane && (
                <PoweredByOctane className={poweredByOctaneClasses}>
                    <span className="mr-1">{poweredByOctaneContent}</span>
                </PoweredByOctane>
            )}
        </div>
    );
};

export const MiniProp = () => (
    <p className="d-inline">
        <span className="spark-text-primary mr-1">{shield}</span>
        {copy.mini_prop}
    </p>
);

export function PoweredByOctane({className, children}) {
    return (
        <p className={classNames("mt-2", className)}>
            {children}
            <Svg
                svg="octane-logo"
                style={{height: 18, color: "var(--ride-octane-blue)"}}
            />
        </p>
    );
};
