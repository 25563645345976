import React from 'react';

import {VehicleHeader} from "../vehicle-header";
import {ValuePropHeader, CoapplicantHeader} from "../value-prop-header";
import {OctaneCoBrandingHeader} from "../octane-co-branding-header";

export const validHeaderTypes = [
    "VehicleHeader",
    "DualValuePropHeader",
    "TripleValuePropHeader",
    "OctaneCoBrandingHeader",
];

export function FormTitle({title}) {
    return (
        <h5 className="text-center spark-text-primary font-weight-bold mt-4 mb-3">
            {title}
        </h5>
    );
}

export function FormHeader({headerType, vehicleConfiguration, accessoriesAmount, isCoapplicantFlow, title, vssConfiguration, partnerRawVehicleData}) {
    /**
     * Header type may be VehicleHeader, DualValuePropHeader, TripleValuePropHeader,
     * OctaneCoBrandingHeader, or null
     */
    const headers = {
        VehicleHeader: () => (
            <>
                <VehicleHeader
                    vssConfiguration={vssConfiguration}
                    vehicleConfiguration={{...vehicleConfiguration, accessoriesAmount}}
                    partnerRawVehicleData={partnerRawVehicleData}
                />
                <FormTitle title={title}/>
            </>
        ),
        DualValuePropHeader: () => (
            <ValuePropHeader
                className="my-3"
            />
        ),
        TripleValuePropHeader: () => (
            <ValuePropHeader
                className="my-3"
                showTripleValueProp
            />
        ),
        OctaneCoBrandingHeader: () => (
            <OctaneCoBrandingHeader className="my-3"/>
        ),
    };

    if(!isCoapplicantFlow) {
        return headerType && headers[headerType]();
    }
    else {
        return <CoapplicantHeader className="my-3"/>
    }
}
