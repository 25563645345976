import React from "react";
import PropTypes from "prop-types";

import {Input} from "../input";
import {validateName, validatePhone, validateZipcode, validateEmail} from "../../utils/validation";
import {formatPhoneNumber, formatPriceInput} from "../../utils/number-utils"

import copy from "./copy.json";

const inputConfigs = Object.freeze({
    name:{
        ...copy.name,
        type: "text",
        inputMode: "text",
        validator: validateName,
    },
    phone:{
        ...copy.phone,
        type: "text",
        inputMode: "numeric",
        validator: validatePhone,
        formatter:formatPhoneNumber,
        unformatter: (val) => val.replace(/\D/g, ''),
    },
    currency:{
        ...copy.currency,
        type: "text",
        inputMode: "numeric",
        formatter:formatPriceInput,
        unformatter: (val) => val.replace(/\D/g, ''),
    },
    zipcode:{
        ...copy.zipcode,
        type: "text",
        inputMode: "numeric",
        validator: validateZipcode,
        formatter:(value) => value.replace(/\D/g, "").substr(0, 5),
        unformatter: (value) => value.replace(/\D/g, "").substr(0, 5),
    },
    email:{
        ...copy.email,
        type: "text",
        validator: validateEmail,
    }
})

/**
 * Reusable component for rendering our validated inputs
 *
 * @param {string}                 value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 type - input type
 * @param {string}                 errorMessage - error message to display when error is a boolean and the input is invalid
 */
export const ValidatedInput = ({
        value,
        onChange,
        error,
        type,
        ...props
    }) => {
    const inputConfig = inputConfigs[type];
    const errorMessage = props.errorMessage ?? inputConfig.errorMessage ?? "";

    const inputProps = {
        ...inputConfig,
        ...props,
        autoValidateAndFormat: true,
        value,
        /* When the error prop is a boolean, an errorMessage can be passed in as a prop or a default one can be added to inputConfig/copy file */
        error: (typeof error === "boolean" && error && errorMessage) ? errorMessage : error,
        onChange,
        onBlur: onChange,
    }

    return <Input {...inputProps}/>
};

ValidatedInput.propTypes = {
    value: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    errorMessage: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
};
