import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'octane-spark';
import styles from '../dealer-selector.module.scss';

export const options = ['20', '50', '100'].map(option => ({value: option, label: option}));

export const MilesSelector = ({ value, onChange }) => {
    const handleChange = (miles) => {
        onChange && onChange(miles);
    };

    return (
        <div className={styles.milesSelectorWrapper}>
            <Select
                id="milesSelector"
                value={value}
                onChange={handleChange}
            >
                {options.map((mile) => (
                    <Select.Option key={mile.value} value={mile.value}>
                        {`${mile.label} miles`}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

MilesSelector.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};
