import React from "react";
import { observer, inject } from "mobx-react";
import { Route, Switch } from "react-router-dom";
import { Container } from "react-bootstrap";

import { ServerError } from "../../components/interstitials";
import { withRideOctaneTitle, PageTitles } from "../../pages/octane-title";
import { startWidgetMessageListener } from "../utils/widget";
import { TwoStepPrequalFlowStore } from "./two-step-prequal-flow-store";
import { ReturnToServer } from "../../components/return-to-server";
import { AboutYouStep } from "../steps/about-you-step";
import { SSNStep } from "../steps/ssn-step";
import { DealerSelector } from "../../prequal/dealer-selector";
import { Offers } from "../../prequal/offers";

export const TwoStepPrequalFlow = inject(
   "rideOctaneStore",
   "apiStore",
   "userStore",
   "themeStore",
   "partnerStore",
   "historyStore",
   "dealershipStore",
   "uxCopyStore"
)(
   observer(
      class _TwoStepPrequalFlow extends React.Component {
         store = new TwoStepPrequalFlowStore(this.props);

         componentDidMount() {
            if (
               this.store.isPartnerDealerWidgetExperience &&
               !this.store.isVehicleSelfSelectionExperience
            ) {
               const stores = {
                  partnerStore: this.store.partnerStore,
                  dealershipStore: this.store.dealershipStore,
                  historyStore: this.store.historyStore,
               };
               const onWidgetTimeout = () => this.store.showServerErrorView("Widget Error.");
               const onWidgetSuccess = () => this.store.initializeVehicleConfiguration();

               startWidgetMessageListener(window, stores, onWidgetTimeout, onWidgetSuccess);
            } else {
               this.store.initializeVehicleConfiguration();
            }

            if(this.store.applicationUuid) {
               this.store.refreshApplicationProgress();
           }
         }

         render() {
            if (this.store.showServerError) {
               return (
                  <Container data-oid="two-step-prequal-flow-error-page">
                     <ServerError
                        origin="two-step-prequal-flow"
                        errorMessage={this.store.serverErrorMessage}
                     />
                  </Container>
               );
            }

            const twoStepPrequalTitle = this.store.isCoapplicantFlow
               ? PageTitles.COAPPLICANT_ABOUT_YOU_TITLE
               : PageTitles.ABOUT_YOU_TITLE;
            const titleWords = this.store.displayVehicle ? [this.store.displayVehicle.name] : [];

            return (
               <div>
                  <Switch>
                     {/* ABOUT YOU STEP */}
                     <Route exact path={["/:slug?/apply", "/:slug?/apply/coapplicant"]}>
                        {withRideOctaneTitle(
                           <AboutYouStep
                              hasFetchedVehicle={this.store.hasFetchedVehicle}
                              vehicleConfiguration={this.store.displayVehicle}
                              rawVehicleData={this.store.rawVehicleData}
                              isCoapplicantFlow={this.store.isCoapplicantFlow}
                              isDummyVehicleEnabled={this.store.isDummyVehicleEnabled}
                              flowStore={this.store}
                           />,
                           [...titleWords, twoStepPrequalTitle]
                        )}
                     </Route>

                     {/* SSN REQUIRED */}
                     <Route exact path="/:slug?/apply/ssn-required/:appUuid">
                        {withRideOctaneTitle(
                           <SSNStep
                              applicationUuid={this.store.applicationUuid}
                              partnerStore={this.props.partnerStore}
                              offersUrl={this.store.getPrequalUrl(
                                 `/offers/${this.store.applicationUuid}`
                              )}
                              aboutYouPrequalUrl={this.store.getPrequalUrl()}
                              hasCompletedAboutYouForm={this.store.hasCompletedTwoStepPrequalForm}
                              hasSubmittedSSN={this.store.hasSubmittedSSN}
                              onSubmitSSN={this.store.onSubmitSSN}
                           />,
                           [...titleWords, PageTitles.SSN_REQUIRED_TITLE]
                        )}
                     </Route>

                     {/* DEALER SELECTOR */}
                     <Route exact path={"/:slug?/apply/dealer/:appUuid"}>
                        {withRideOctaneTitle(
                           <DealerSelector
                              zipCodeUser={this.props.userStore.userInfo["zip_code"]}
                              onSubmitDealership={this.store.onSubmitDealership}
                           />,
                           [...titleWords, PageTitles.DEALER_SELECTOR]
                        )}
                     </Route>

                     {/* OFFERS */}
                     <Route
                        exact
                        path={[
                           "/:slug?/apply/offers/:appUuid",
                           "/:slug?/apply/offers/:appUuid/:applicantUuid",
                        ]}
                     >
                        {withRideOctaneTitle(
                           <Offers
                              applicationUuid={this.store.applicationUuid}
                              applicantUuid={this.store.primaryApplicantUuid}
                              hasCompletedAboutYouForm={this.store.hasCompletedTwoStepPrequalForm}
                              hasSubmittedSSN={this.store.hasSubmittedSSN}
                              aboutYouPrequalUrl={this.store.getPrequalUrl()}
                              ssnRequiredUrl={this.store.getPrequalUrl(
                                 `/ssn-required/${this.store.applicationUuid}`
                              )}
                              isApplicantCMPFlow={!!this.store.primaryApplicantUuid}
                              accessoriesAmount={this.store.accessoriesAmount}
                              vehicleConfiguration={this.store.displayVehicle}
                              offersEndpoint={`/api/v2/applications/${this.store.applicationUuid}/`}
                           />,
                           [...titleWords, PageTitles.OFFERS_TITLE]
                        )}
                     </Route>

                     <Route component={ReturnToServer} />
                  </Switch>
               </div>
            );
         }
      }
   )
);
