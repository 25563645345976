import React from 'react';
import {PropTypes} from 'prop-types';

import copy from "../copy";
import {DateInput} from "../../components/date-input";
import {DateInputType} from "../../utils/date-utils";
import {validatePastMonthAndYear} from "../../utils/validation";
/**
 * Reusable component for rendering employer date form field
 *
 * @param {boolean|object}         value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 controlId - input control id
 */
export function EmployerDateField({onChange, ...props}) {
    const changeHandler = (e, value, error) => {
        onChange({value, error})
    }

    return (
        <DateInput
            {...copy.employer_date}
            inputType={DateInputType.MONTH_AND_YEAR}
            validator={validatePastMonthAndYear}
            required
            onChange={changeHandler}
            onBlur={changeHandler}
            {...props}
        />
    );
}

EmployerDateField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    controlId: PropTypes.string, 
};
