import React from "react";
import classnames from "classnames";
import styles from "./vehicle-header.module.scss";
import PropTypes from "prop-types";
import {titleWithPrice} from "./utils";


/**
 *
 * Reusable Vehicle Header with model name and MSRP at a minimum.
 * Displays model image if given and a configured price if
 * there is an accessories cost greater than 0 given
 * used when there is a vehicleConfiguration
 *
 * @param {string}          name - the name of the model
 * @param {string|number}   msrp - model's msrp
 * @param {string|number}   accessoriesAmount - accessories cost used to determine Configured, optional
 * @param {string}          image - URL for a model image, optional
 * @param {string|object}   className - additional class(es) added to wrapping element, optional
 */
export const VehicleConfigurationHeader = ({name, msrp, accessoriesAmount, image, className = ""}) => {
    const containerClasses = classnames(
        "border-bottom",
        styles.container,
        {
            "justify-content-center": !image
        },
        className,
    );

    const msrpInfo = titleWithPrice("MSRP", msrp);

    /* Only set configured if the accessoriesAmount is greater than 0, do not set if it will be the same as MSRP */
    const configuredInfo =
        Number(accessoriesAmount) > 0
            ? titleWithPrice("Configured", accessoriesAmount, msrp)
            : null;

    return (
        <div className={containerClasses}>
            {/* Render the model image if given */}
            {!!image && <img src={image} alt={name} className={styles.image}/>}

            {/* Center the text if there is no image */}
            <div className={!image ? "text-center" : null}>
                <p className={`spark-text-14px-bold ${styles.name}`}>
                    {name}
                </p>

                {msrpInfo}
                {configuredInfo}
            </div>
        </div>
    );
};

VehicleConfigurationHeader.propTypes = {
    name: PropTypes.string.isRequired,
    msrp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    accessoriesAmount: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    image: PropTypes.string,
    className: PropTypes.string,
};

export default VehicleConfigurationHeader;
