import React from 'react';
import PropTypes from 'prop-types';
import {observer, inject} from 'mobx-react';
import {Container} from "react-bootstrap";
import {Redirect} from 'react-router-dom';
import classNames from 'classnames';

import {FancySpinner} from "../../components/spinners/fancy-spinner";
import {VehicleFinancingUnavailable} from "../../components/vehicle-financing-unavailable";

import {
    FirstNameInputAndLastNameInput,
    DateOfBirthPicker,
    EmailInput,
    Street1Input,
    Street2Input,
    CityInput,
    StateDropdownAndZipCodeInput,
    ResidentialStatusBtns,
    ResidentialDateInput,
    IncomeInput,
    EmploymentStatusDropdown,
    SubmitAboutYouFormButton,
    ContactPreferenceBtns,
    PhoneNumberInput,
    PurchaseIntentBtns,
    TradeInIntentInput,
    CoapplicantIntentInput,
    RelationshipToPrimaryDropdown,
    ReusePrimaryApplicantAddressInput,
} from "./about-you-form-fields";
import {FormHeader} from "../../components/form-header";

import { logVehicleFinancingUnavailable } from './about-you-helpers';
import {AboutYouStore} from "./about-you-store";
import {Captcha} from "../../components/captcha";
import {ServerError} from "../../components/interstitials";
import {DisclosureBlock} from "../../components/disclosure-block";
import {BrandingValueProp} from "../../components/branding";
import {SSNInput} from "../../components/ssn-input";
import {isInIFrame, postMessageScrollIntoView} from "../../utils/iframe-utils";

import copy from "./copy";
import styles from "./about-you.module.scss";
import {pushApplicationFlow} from "../../utils/gtm-utils";

export const AboutYou = inject('userStore', 'apiStore', 'uxCopyStore', 'partnerStore', 'dealershipStore', 'rideOctaneStore','historyStore')(
    observer(
        class _AboutYou extends React.Component {
            static propTypes = {
                hasFetchedVehicle: PropTypes.bool.isRequired,
                accessoriesAmount: PropTypes.number,
                vehicleConfiguration: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    msrp: PropTypes.number.isRequired,
                    default_image: PropTypes.string.isRequired,
                }),
                isDummyVehicleEnabled: PropTypes.bool.isRequired,
                onClickContinue: PropTypes.func.isRequired,
                onClickContinueToCoapplicant: PropTypes.func.isRequired,
                widgetError: PropTypes.bool,
                isCoapplicantFlow: PropTypes.bool,
                fieldNamePrefix: PropTypes.string.isRequired,
                vssConfiguration: PropTypes.object
            };

            captchaRef = React.createRef();
            store = new AboutYouStore(this.props);

            componentDidMount() {
                window.scrollTo(0, 0);
                this.store.fetchPurchaseIntentOptions();
                if (this.captchaRef && this.captchaRef.current) {
                    this.captchaRef.current.reset();
                }

                if (this.props.partnerStore.isReveo) {
                    // Start handler to gather reveo custom info
                    this.store.startReveoMessageListener(window);
                }
                if(this.props.partnerStore.isRV){
                    this.store.startRvMessageListener(window);
                }
                pushApplicationFlow(null, 'about-you');
            }

            launchCaptcha = () => {
                if (isInIFrame()) {
                    // listener in ride-octane-api/ride_octane_api/templates/partner-cta-widget/campingworld/widget.js
                    postMessageScrollIntoView(window.top);
                } else {
                    // for non-iframe experiences, doesn't work in iframe
                    window.scrollTo(0, 0);
                }

                if (process.env.REACT_APP_BYPASS_CAPTCHA) {
                    this.props.onClickContinue();
                } else {
                    this.captchaRef.current.execute();
                }
            };

            isCoapplicantView = () => {
                if(!this.props.historyStore.history) return false;
                const isCoapplicantView = this.props.historyStore.history.location.pathname.includes(`/apply/coapplicant`);
                return isCoapplicantView;
            };

            isCoapplicantRoute = () => {
                return (this.props.partnerStore.isCoapplicantEnabled && this.store.getFieldValueByName("coapplicant_intent").value && !this.isCoapplicantView());
            };

            onChangeSSN = e => (
                this.store.updateFieldValue(this.props.fieldNamePrefix + 'ssn', e.target.value, e.type === 'blur')
            );

            render() {
                // just evaluate once, instead of calling method multiple times
                const isCoapplicantView = this.isCoapplicantView();

                // primary address locked
                const isPrimaryAddressLocked = this.store.userAddressInfoLocked && !isCoapplicantView

                if(this.store.showServerErrorView || this.props.widgetError) {
                    return (
                        <Container data-oid="about-you-error-page">
                            <ServerError 
                                origin="about-you"
                                errorMessage={this.store.serverErrorMessage || "Widget Error. "}
                            />
                        </Container>
                    );
                }

                // If we haven't fetched the purchase intent options or
                // we haven't fetched the vehicle and we need the vehicle for the VehicleHeader
                // display the fancy loading spinner
                if (
                    !this.store.hasFetchedPurchaseIntentOptions ||
                    (!this.props.hasFetchedVehicle && this.store.isVehicleRequiredToRender)
                ) {
                    return (
                        <Container data-oid="about-you-loading">
                            <FancySpinner className="d-block mx-auto my-5"/>
                        </Container>
                    );
                }

                // If the vehicle has been fetched and there is no vehicle configuration 
                // and this partner doesn't decision on dummmy vehicles
                if (
                    this.props.hasFetchedVehicle &&
                    !this.props.vehicleConfiguration &&
                    !this.props.isDummyVehicleEnabled
                ) {
                    // For certain partners (BRP), to give the customer a better user experience, we don't render the
                    // vehicle not found view and instead just redirect them to the homepage to select the vehicle through us
                    if (!this.store.showVehicleFinancingUnavailable)  {
                        return <Redirect to='/'/>;
                    }
                    logVehicleFinancingUnavailable(
                        this.props.partnerStore.partnerIdentifier, 
                        this.props.hasFetchedVehicle, 
                        this.props.vehicleConfiguration, 
                        this.props.isDummyVehicleEnabled
                        );
                    return (
                        <Container data-oid="about-you-unavailable">
                            <VehicleFinancingUnavailable/>
                        </Container>
                    );
                }

                return (
                    <Container data-oid={this.props.fieldNamePrefix + "about-you-form"} key={isCoapplicantView ? "coapplicant_flow" : "primary_applicant_flow"} 
                    className={classNames({[styles.container]: this.props.partnerStore.hasMultiColumnDisplay})}>
                        {/* Desktop classes should only be rendered for reveo, RV, or VWO tests (may be removed after testing) as computed in about-you-store.js */}
                        <div className={classNames("w-450px mw-100 mx-auto", {
                            [`w-md-800px w-sm-600px ${styles.form} mt-4`]: this.props.partnerStore.hasMultiColumnDisplay,
                            "mb-5 ": !this.props.partnerStore.hasMultiColumnDisplay,
                        })}>
                            <div>
                                <FormHeader
                                    title={copy.title}
                                    headerType={this.store.aboutYouHeaderType}
                                    vehicleConfiguration={this.props.vehicleConfiguration}
                                    vssConfiguration={this.props.vssConfiguration}
                                    partnerRawVehicleData={this.props.partnerStore.rawVehicle}
                                    accessoriesAmount={this.props.accessoriesAmount}
                                    isCoapplicantFlow={isCoapplicantView}
                                />
                            </div>
                            
                            {isCoapplicantView && (
                                <RelationshipToPrimaryDropdown
                                    relationship_to_primary={this.store.getFieldValueByName("relationship_to_primary")}
                                    updateFieldValue={this.store.updateFieldValue}
                                />
                            )}
                            
                            <FirstNameInputAndLastNameInput
                                first_name={this.store.getFieldValueByName("first_name", this.props.fieldNamePrefix)}
                                last_name={this.store.getFieldValueByName("last_name", this.props.fieldNamePrefix)}
                                updateFieldValue={this.store.updateFieldValue}
                                fieldNamePrefix={this.props.fieldNamePrefix}
                            />
                            <EmailInput
                                email={this.store.getFieldValueByName("email", this.props.fieldNamePrefix)}
                                updateFieldValue={this.store.updateFieldValue}
                                fieldNamePrefix={this.props.fieldNamePrefix}
                            />
                            <DateOfBirthPicker
                                date_of_birth={this.store.getFieldValueByName("date_of_birth", this.props.fieldNamePrefix)}
                                updateFieldValue={this.store.updateFieldValue}
                                fieldNamePrefix={this.props.fieldNamePrefix}
                            />
                            {(isCoapplicantView) && (
                                <ReusePrimaryApplicantAddressInput 
                                    reusePrimaryApplicantAddressForCoapplicant={this.store.reusePrimaryApplicantAddressForCoapplicant}
                                />
                            )}
                            <Street1Input
                                street1={this.store.getFieldValueByName("street1", this.props.fieldNamePrefix)}
                                updateFieldValue={this.store.updateFieldValue}
                                fieldNamePrefix={this.props.fieldNamePrefix}
                                disabled={this.store.disableAddressFields || isPrimaryAddressLocked}
                            />
                            <Street2Input
                                street2={this.store.getFieldValueByName("street2", this.props.fieldNamePrefix, false)}
                                updateFieldValue={this.store.updateFieldValue}
                                fieldNamePrefix={this.props.fieldNamePrefix}
                                disabled={this.store.disableAddressFields}
                            />
                            <CityInput
                                city={this.store.getFieldValueByName("city", this.props.fieldNamePrefix)}
                                updateFieldValue={this.store.updateFieldValue}
                                fieldNamePrefix={this.props.fieldNamePrefix}
                                disabled={this.store.disableAddressFields  || isPrimaryAddressLocked}
                            />
                            <StateDropdownAndZipCodeInput
                                state={this.store.getFieldValueByName("state", this.props.fieldNamePrefix)}
                                zip_code={this.store.getFieldValueByName("zip_code", this.props.fieldNamePrefix)}
                                updateFieldValue={this.store.updateFieldValue}
                                fieldNamePrefix={this.props.fieldNamePrefix}
                                disabledZip={this.store.disableAddressFields  || isPrimaryAddressLocked}
                                disabledState={this.store.disableAddressFields  || (isPrimaryAddressLocked && this.store.userAddressStateMapped)}
                            />
                            <ResidentialStatusBtns
                                residential_status={this.store.getFieldValueByName("residential_status", this.props.fieldNamePrefix)}
                                updateFieldValue={this.store.updateFieldValue}
                                fieldNamePrefix={this.props.fieldNamePrefix}
                            />
                            <ResidentialDateInput
                                residential_date={this.store.getFieldValueByName("residential_date", this.props.fieldNamePrefix)}
                                updateFieldValue={this.store.updateFieldValue}
                                fieldNamePrefix={this.props.fieldNamePrefix}
                            />
                            <EmploymentStatusDropdown
                                employment_status={this.store.getFieldValueByName("employment_status", this.props.fieldNamePrefix)}
                                updateFieldValue={this.store.updateFieldValue}
                                fieldNamePrefix={this.props.fieldNamePrefix}
                            />
                            <IncomeInput
                                yearly_income={this.store.getFieldValueByName("yearly_income", this.props.fieldNamePrefix)}
                                updateFieldValue={this.store.updateFieldValue}
                                fieldNamePrefix={this.props.fieldNamePrefix}
                            />
                            {/* Only display Trade-In and Co-Applicant fields for RV partner experiences */}
                            {this.props.partnerStore.isRV && !isCoapplicantView && (
                                    <TradeInIntentInput
                                        trade_in_intent={this.store.getFieldValueByName("trade_in_intent")}
                                        updateFieldValue={this.store.updateFieldValue}
                                    />
                            )}
                            {(this.props.partnerStore.isRV || this.props.partnerStore.isCoapplicantEnabled) && !isCoapplicantView && (
                                    <CoapplicantIntentInput
                                        coapplicant_intent={this.store.getFieldValueByName("coapplicant_intent")}
                                        updateFieldValue={this.store.updateFieldValue}
                                        isCoapplicantDtc={this.props.partnerStore.isCoapplicantEnabled}
                                    />
                            )}
                            {this.store.showContactPreferenceButtons && !isCoapplicantView && (
                                <ContactPreferenceBtns
                                    contact_preference={this.store.getFieldValueByName("contact_preference")}
                                    updateContactPreference={this.store.updateContactPreference}
                                    contactPreferenceOptions={this.store.contactPreferenceOptions}
                                    fieldNamePrefix={this.props.fieldNamePrefix}
                                    isCoapplicantFlow={isCoapplicantView}
                                />
                            )}
                            <PhoneNumberInput
                                phone_number={this.store.getFieldValueByName("phone_number", this.props.fieldNamePrefix)}
                                updateFieldValue={this.store.updateFieldValue}
                                fieldNamePrefix={this.props.fieldNamePrefix}
                            />
                            {this.store.showPurchaseIntentButtons && !isCoapplicantView && (
                                <PurchaseIntentBtns
                                    purchase_intent={this.store.getFieldValueByName("purchase_intent")}
                                    purchaseIntentOptions={this.store.purchaseIntentOptions}
                                    updateFieldValue={this.store.updateFieldValue}
                                />
                            )}
                            {this.props.partnerStore.isCoapplicantEnabled && (this.store.getFieldValueByName("coapplicant_intent").value || isCoapplicantView) && (
                                <div className={styles.ssn}>
                                    <SSNInput
                                        {...copy["ssn"]}
                                        onChange={this.onChangeSSN}
                                        value={this.store.getFieldValueByName("ssn", this.props.fieldNamePrefix).value}
                                        error={this.store.getFieldValueByName("ssn", this.props.fieldNamePrefix).showErrorState}
                                    />
                                </div>
                            )}
                            <div>
                                <BrandingValueProp 
                                    showPoweredByOctane={this.store.showPoweredByOctane}
                                    showDesktopLayout={!!this.props.partnerStore.hasMultiColumnDisplay}
                                    showBrandingForCampingWorld={this.props.partnerStore.isRV}
                                    showMiniProp
                                />
                                <DisclosureBlock
                                    showCheckBox
                                    showRuleLine
                                    disclosureText={this.store.prequalDisclosure}
                                    id={this.props.fieldNamePrefix + "consent"}
                                    onChange={isCoapplicantView ? this.store.updateCoapplicantPrequalDisclosureConsent : this.store.updatePrequalDisclosureConsent}
                                />
                                {this.store.showConsentTextCheckbox && (
                                    <DisclosureBlock
                                        showCheckBox
                                        disclosureText={this.store.showConsentText}
                                        id="partner_consent"
                                        onChange={this.store.updatePartnerConsent}
                                        isChecked={this.store.partnerConsent}
                                    />
                                )}
                                <DisclosureBlock
                                    showCheckBox
                                    disclosureText={copy.text_consent.labelContent}
                                    id="text-consent"
                                    onChange={this.store.updateTextConsent}
                                    isChecked={this.store.textConsent}
                                />
                                <Captcha
                                    captchaRef={this.captchaRef}
                                    onClickContinue={this.props.onClickContinue}
                                />
                                <SubmitAboutYouFormButton
                                    canContinue={this.props.hasFetchedVehicle && this.store.canContinue(isCoapplicantView, this.props.fieldNamePrefix)}
                                    onClick={this.isCoapplicantRoute() ? this.props.onClickContinueToCoapplicant : this.launchCaptcha}
                                    continueButtonLabel={this.store.getContinueButtonLabel(this.isCoapplicantRoute())}
                                    isReveo={this.props.partnerStore.isReveo}
                                    isRV={this.props.partnerStore.isRV}
                                    fieldNamePrefix={this.props.fieldNamePrefix}
                                />
                            </div>
                        </div>
                    </Container>
                );
            }
        }
    )
);
