import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";

import SSNStep from "./ssn-step";
import { SSNStepStore } from "./ssn-step-store";

export const SSNStepContainer = inject("partnerStore")(
   observer(
      ({
         partnerStore,
         hasCompletedAboutYouForm,
         onSubmitSSN,
         offersUrl,
         aboutYouPrequalUrl,
         applicationUuid,
         hasSubmittedSSN,
      }) => {
         const store = new SSNStepStore(partnerStore);

         /*

        1. CHECK prequalStore.hasCompletedAboutYouForm is true
        -> redirect to about you form
        2. CHECK if hasSubmittedSSN is true
        -> redirect to offers page
        3. RENDER form w/ ssn input & continue button
      
        */

         if (!hasCompletedAboutYouForm) {
            return <Redirect to={aboutYouPrequalUrl} />;
         }

         if (!applicationUuid || hasSubmittedSSN) {
            return <Redirect to={offersUrl} />;
         }

         return (
            <SSNStep
               store={store}
               isPartnerRV={partnerStore.isRV}
               isPartnerReveo={partnerStore.isReveo}
               onSubmitSSN={onSubmitSSN}
            />
         );
      }
   )
);

SSNStepContainer.propTypes = {
   hasCompletedAboutYouForm: PropTypes.bool.isRequired,
   onSubmitSSN: PropTypes.func.isRequired,
   offersUrl: PropTypes.string.isRequired,
   aboutYouPrequalUrl: PropTypes.string.isRequired,
   applicationUuid: PropTypes.string.isRequired,
   hasSubmittedSSN: PropTypes.bool.isRequired,
};
