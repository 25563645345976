import get from 'lodash.get';
import {decorate, observable, computed, action} from 'mobx';

import {
    parseRawVehicleDataFromQueryParams,
    vehicleDisplayData,
    fetchVehicleConfigFromSlug,
    fetchVehicleConfigFromVehicleIdentifier
} from "./utils/vehicle";

/*
* Basic logic that is reused by all flows exteding BaseFlowStore
*/
export class BaseFlowStore {
    // references
    apiStore = null;
    userStore = null;
    themeStore = null;
    partnerStore = null;
    historyStore = null;
    dealershipStore = null;

    // observables
    vehicleConfigurationSlug = null;
    vehicleConfiguration = null;
    hasFetchedVehicle = false;
    // data sent from Vehicle Self Selection - VSS
    rawVehicleData = null

    // Applicants uuid
    primaryApplicantUuid = null;
    coApplicantUuid = null;

    /**
     * True if this is a valid prequal url
     *  (e.g. has one of the following
     *      - a vehicle configuration slug
     *      - a partner and vehicle identifier
     *      - a partner and partner vehicle identifier
     *      - or none of the above with isDummyVehicleEnabled true
     */
    get isValidExperience() {
        return !!(
            this.vehicleConfigurationSlug ||
            this.partnerStore.vehicleIdentifier ||
            this.partnerStore.partnerVehicleMatch ||
            this.isDummyVehicleEnabled ||
            this.historyStore.queryParams.isFlex
        );
    }

    /**
     * True if this is a partner experience
     */
    get isPartnerExperience() {
        if (!this.isValidExperience) {
            return false;
        }
        return !!this.partnerStore.partnerIdentifier;
    }

    /**
     * True if this is a partner experience that uses a partner's vehicle identifier
     */
    get isPartnerWithVehicleIdentifierExperience() {
        if (!this.isPartnerExperience) {
            return false;
        }
        return !!this.partnerStore.vehicleIdentifier;
    }

    /**
     * True if
     *  - this is a partner experience
     *  - this is not a partner with a vehicle identifier exprience
     *  - there is a PartnerVehicleMatch
     */
    get isPartnerWithVehicleMatchExperience() {
        return (
            this.isPartnerExperience &&
            !this.isPartnerWithVehicleIdentifierExperience &&
            !!this.partnerStore.partnerVehicleMatch
        );
    }

    /**
     * True if
     *  - this is a partner experience
     *  - this is not a partner with a vehicle identifier experience
     *  - there is no vehicle configuration slug
     *  - there is no partner vehicle match id
     *  - it is not a flex experience
     */
    get isPartnerDealerWidgetExperience() {
        return (
            this.isPartnerExperience &&
            !this.isPartnerWithVehicleIdentifierExperience &&
            !this.vehicleConfigurationSlug &&
            !this.partnerStore.isReveo &&
            !this.partnerStore.isRV &&
            !this.isFlexExperience &&
            // We check the query params cause the widgetMessageHandler can
            // also set partnerStore.partnerVehicleMatch
            !this.historyStore.queryParams.partnerVehicleMatch
        );
    }

    get isFlexExperience() {
        return (
            this.historyStore.queryParams.isFlex === "1"
        );
    }

    /**
     * True if this is a partner that allows decisioning on "dummy vehicles"
     *
     * Note: Decisioning on dummy vehicles is only used if
     * the partner enables it and there is NOT a:
     *  - vehicle slug
     *  - partner vehicle identifier
     *  - a partner vehicle match
     */
    get isDummyVehicleEnabled() {
        return get(this.partnerStore, "partner.enable_dummy_vehicle", false);
    }

    /**
     * True if the query params rawVehicleData is 1
     */
    get isVehicleSelfSelectionExperience() {
        return this.historyStore.queryParams.rawVehicleData === '1'
    }

    /**
     * True if the URL contains /apply/coapplicant
     */
    get isCoapplicantFlow() {
        return this.historyStore?.pathname.includes("/apply/coapplicant") || false;
    }

    /**
     * Determines what the vehicle configuration should be and updates it
     *  - Vehicle configuration is fetched if:
     *      - There is a slug
     *      - There is no slug and this is a partner vehicle identifier experience
     *  - Vehicle configuration comes from the partner vehicle match if:
     *      - There is no slug and there is a partnerVehicleMatch
     *  - Vehicle configuration is null if the above are false
     */
    async initializeVehicleConfiguration() {
        let vehicleConfiguration = null;

        const {partnerIdentifier, vehicleIdentifier} = this.partnerStore;

        if(this.isPartnerWithVehicleIdentifierExperience){
            console.debug("Vehicle configuration from identifier");
            vehicleConfiguration = await fetchVehicleConfigFromVehicleIdentifier(vehicleIdentifier, partnerIdentifier);
        }else if (this.isPartnerWithVehicleMatchExperience) {
            console.debug("Vehicle configuration from vehicle match");
            vehicleConfiguration = this.partnerStore.partnerVehicleMatch.matched_vehicle;
        }else if (this.vehicleConfigurationSlug) {
            console.debug("Vehicle configuration from Slug");
            vehicleConfiguration = await fetchVehicleConfigFromSlug(this.vehicleConfigurationSlug, partnerIdentifier);
        }

        // if it's a VSS experience save data comes from query params in rawVehicleData
        if(this.isVehicleSelfSelectionExperience) {
            console.debug("Vehicle configuration updated from query params");
            const rawVehicleData = parseRawVehicleDataFromQueryParams(this.historyStore.queryParams);
            this.rawVehicleData = rawVehicleData;
            if (!rawVehicleData) return;

            vehicleConfiguration = Object.assign(vehicleConfiguration || {}, {
                name: rawVehicleData.raw_name,
                msrp: rawVehicleData.raw_msrp,
            })
        }

        this.updateVehicleConfiguration({vehicleConfiguration});
    }

    /**
     * Updates vehicleConfiguration and the following observables
     *  - vehicleConfigurationSlug (if this is a partner experience with vehicle id or vehicle match)
     *  - accessoriesAmount
     *  - hasFetchedVehicle
     * @param {object} vehicleConfiguration
     */
    updateVehicleConfiguration({vehicleConfiguration}) {
        const {msrp = 0, vehicleDisplayName = null, accessoriesAmount = 0} = this.historyStore.queryParams;

        this.hasFetchedVehicle = true;
        this.accessoriesAmount = parseFloat(accessoriesAmount) || 0;
        if (vehicleConfiguration) {
            this.vehicleConfiguration = vehicleConfiguration;

            if (this.isPartnerExperience) {

                // override name
                if (vehicleDisplayName) {
                    this.vehicleConfiguration.name = vehicleDisplayName;
                }

                // log error if msrp doesn't match
                if (msrp && (parseFloat(msrp) !== parseFloat(this.vehicleConfiguration.msrp))) {
                    console.error('Supplied msrp and our msrp are not equal');
                }

                // set vehicleConfigurationSlug if fetched using partner vehicle identifier or vehicle match
                if (this.isPartnerWithVehicleIdentifierExperience || this.isPartnerWithVehicleMatchExperience) {
                    this.vehicleConfigurationSlug = vehicleConfiguration.slug;
                }
            }
        }

        return this;
    }

    /**
     * Vehicle data to display.
     * Uses data from this.partnerStore.partnerVehicleMatch if available,
     * otherwise uses data from this.vehicleConfiguration
     */
    get displayVehicle() {
        return vehicleDisplayData({
            vehicleConfiguration: this.vehicleConfiguration,
            partnerVehicleMatch: this.partnerStore.partnerVehicleMatch
        });
    }

    updateApplicationUuid = (applicationUuid) => {
        if (!applicationUuid) {
            throw new Error(`BaseFlowStore.updateApplicationUuid received invalid or null uuid value: "${applicationUuid}"`);
        }
        this.applicationUuid = applicationUuid;
    }

    updateApplicantUuids = ({primaryApplicantUuid = null, coApplicantUuid = null}) => {
        if(primaryApplicantUuid){
            this.primaryApplicantUuid = primaryApplicantUuid;
        }

        if(coApplicantUuid){
            this.coApplicantUuid = coApplicantUuid;
        }
    }

    showServerErrorView(message){
        this.serverErrorMessage = message;

        // This must be the last statement to avoid multiple rendering of the ServerError view
        this.showServerError = true;
    }

    /**
     * Helper - Returns url string for the specified subRoute
     *
     * @param {string} [subRoute]
     *  (e.g offers...)
     */
    getPrequalUrl(subRoute = "") {
        if (this.isPartnerWithVehicleIdentifierExperience || this.isPartnerWithVehicleMatchExperience || this.isVehicleSelfSelectionExperience) {
            return `/apply${subRoute}`;
        }

        // If there is no vehicle and "dummy vehicle" is enabled, don't add a slug
        if (!this.vehicleConfiguration && this.isDummyVehicleEnabled) {
            return `/apply${subRoute}`;
        }

        return this.vehicleConfigurationSlug ?
            `/${this.vehicleConfigurationSlug}/apply${subRoute}` : `/apply${subRoute}`;
    }

    constructor({rideOctaneStore, apiStore, userStore, themeStore, partnerStore, historyStore, dealershipStore, match}) {
        // save references
        this.rideOctaneStore = rideOctaneStore;
        this.apiStore = apiStore;
        this.userStore = userStore;
        this.themeStore = themeStore;
        this.partnerStore = partnerStore;
        this.historyStore = historyStore;
        this.dealershipStore = dealershipStore;
        this.showServerError = false;
        this.serverErrorMessage = false;

        this.applicationUuid = null;
        this.primaryApplicantUuid = null;
        this.coApplicantUuid = null;

        // check for vehicle configuration slug in url
        if (match.params.slug) {
            this.vehicleConfigurationSlug = match.params.slug;
        }
    }
}

decorate(BaseFlowStore, {
    vehicleConfigurationSlug: observable,
    vehicleConfiguration: observable,
    hasFetchedVehicle: observable,
    applicationUuid: observable,
    primaryApplicantUuid: observable,
    coApplicantUuid: observable,
    showServerError: observable,
    serverErrorMessage: observable,

    isValidExperience: computed,
    isPartnerExperience: computed,
    isPartnerWithVehicleIdentifierExperience: computed,
    isPartnerDealerWidgetExperience: computed,
    isDummyVehicleEnabled: computed,
    isFlexExperience: computed,
    displayVehicle: computed,
    isCoapplicantFlow: computed,

    showServerErrorView: action,
    updateVehicleConfiguration: action,
    updateApplicationUuid: action,
    updateApplicantUuids: action,
    getPrequalUrl: action,
});
