import React from 'react';
import PropTypes from "prop-types";

import copy from "../copy";
import {ButtonSelectField} from "../../components/button-select-field";
import {tradeInIntents} from "../../enums/tradeInIntent";

/**
 * Reusable component for rendering trade in intent form field
 *
 * @param {string}                 value - input value
 * @param {boolean|string}         error - whether or not the input should show the error view. When string, contains the error message
 * @param {function}               onChange - onChange event handler
 * @param {string}                 controlId - input control id
 * @param {object}                 partnerStore - partner store object
 */
export const TradeInIntentField = ({onChange, partnerStore,...props}) => {
    const handleChange = (event) => {
        onChange({value: event.key === 'Yes', error: false});
    }

    let showField = partnerStore ? !!partnerStore.isRV : true;

    return showField && (
        <ButtonSelectField
            {...copy.trade_in_intent}
            selected={props.value}
            onChange={handleChange}
            items={tradeInIntents}
            {...props}
        />
    )
}

TradeInIntentField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string]),
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    controlId: PropTypes.string, 
};
