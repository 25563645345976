export const initialFieldStates = {
    first_name: {value: "", error: false},
    last_name: {value: "", error: false},
    email: {value: "", error: false},
    date_of_birth: {value: null, error: false},
    street1: {value: "", error: false},
    street2: {value: "", error: false},
    city: {value: "", error: false},
    state: {value: "", error: false},
    zip_code: {value: "", error: false},
    residential_status: {value: "O", error: false},
    residential_date: {value: null, error: false},
    employment_status: {value: "", error: false},
    yearly_income: {value: "", error: false},
    contact_preference: {value: "contact_by_phone", error: false},
    phone_number: {value: "", error: false},
    purchase_intent: {value: "WITHIN_A_WEEK", error: false},
    citizenship_status: {value: "", error: false},
    monthly_housing_payment: {value: "", error: false},
    employer_name: {value: "", error: false},
    employer_phone: {value: "", error: false},
    job_title: {value: "", error: false},
    employer_date: {value: null, error: false},
    social_security_number: {value: "", error: false},
    trade_in_intent: {value: 'No', error: false},
    coapplicant_intent: {value: false, error: false},
    relationship_to_primary: {value: "", error: false},
    reuse_primary_address: {value: false, error: false},
};

export const testApplicantFieldStates = {
    george: {
        first_name: {value: "GEORGE", error: false},
        last_name: {value: "BEER", error: false},
        email: {value: "youremail+airtest@octanelending.com", error: false},
        date_of_birth: {value: {day: "15", month: "09", year: "1941"}, error: false},
        street1: {value: "116 State St #85", error: false},
        street2: {value: "", error: false},
        city: {value: "Holmen", error: false},
        state: {value: "NV", error: false},
        zip_code: {value: "54636", error: false},
        residential_status: {value: "R", error: false},
        residential_date: {value: {month: "1", year: "2020"}, error: false},
        employment_status: {value: "employed", error: false},
        yearly_income: {value: "120000", error: false},
        contact_preference: {value: "contact_by_phone", error: false},
        phone_number: {value: "6085261867", error: false},
        purchase_intent: {value: "WITHIN_A_WEEK", error: false},
        citizenship_status: {value: "us_citizen", error: false},
        monthly_housing_payment: {value: "9000", error: false},
        employer_name: {value: "Octane", error: false},
        employer_phone: {value: "5165165165", error: false},
        job_title: {value: "Bug Hunter", error: false},
        employer_date: {value: {month: "1", year: "2020"}, error: false},
        social_security_number: {value: "666570450", error: false},
        trade_in_intent: {value: 'No', error: false},
        coapplicant_intent: {value: false, error: false},
        relationship_to_primary: {value: "", error: false},
        reuse_primary_address: {value: false, error: false},
    }
}
