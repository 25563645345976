import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown/with-html";
import classnames from "classnames";

import {directionSign, noCommitment, shield} from "../svg/visual-icons";
import styles from "./value-prop-header.module.scss";
import {inject, observer} from 'mobx-react';
import copy from "../../prequal/about-you/copy.json";

/**
 * Reusable component for rendering our value props
 *
 * @param {string|object}   [className] - additional class(es) added to header element
 * @param {bool}            [showTripleValueProp] - should the component show 3 value props instead of the default of 2
 */
export const ValuePropHeader = ({className = "", showTripleValueProp}) => {
    /*
    The value props are displayed in this order
    The second value prop is only displayed when showTripleValueProp is true
    */
    const valueProps = [
        {
            icon: shield,
            heading: "No Impact",
            text: "Initial soft-pull\n\ncredit check",
        },
        {
            icon: noCommitment,
            heading: "Transparent",
            text: "See rates and payments",
        },
        {
            icon: directionSign,
            heading: "No Commitment",
            text: "Prequalification valid for 30 days",
        },
    ];

    /* If we aren't showing 3 value props, remove the second value prop */
    if (!showTripleValueProp) valueProps.splice(1, 1);

    return (
        <div className={classnames("text-center", className)}>
            <h5 className="spark-text-20px-bold mb-3">One Form - Instant Decision</h5>
            <div
                className={classnames(
                    styles.valuePropsContainer,
                    "d-flex justify-content-around",
                    {
                        [styles.tripleValueProp]: showTripleValueProp,
                    },
                )}
            >
                {valueProps.map((valueProp, index) => (
                    <ValueProp {...valueProp} key={index} />
                ))}
            </div>
        </div>
    );
};

/**
 * @param {string|object}   [className] - additional class(es) added to header element
 */
 export const CoapplicantHeader = inject('uxCopyStore')(observer(({uxCopyStore, className = ""}) => {
        const coapplicantPageTitle = () =>  uxCopyStore.getAboutYouCopy('coapplicant_title', copy.coapplicant_title)
        const coapplicantPageTitleSecondary = () => uxCopyStore.getAboutYouCopy('coapplicant_secondary_title', copy.coapplicant_secondary_title)

        return (
            <div className={classnames("text-center", className)}>
                <h5 className="spark-text-20px-bold mb-3">{coapplicantPageTitle()}</h5>
                <h2 className="spark-text-16px mb-3">{coapplicantPageTitleSecondary()}</h2>
            </div>
        );
    })
);

/* Helper to render the individual value prop with an icon, mini heading and additional text*/
const ValueProp = ({icon, heading, text}) => (
    <div className="spark-text-primary">
        {icon}
        <h6 className="mt-2 mb-0">{heading}</h6>
        <ReactMarkdown
            source={text}
            renderers={{
                paragraph: ({children}) => (
                    <p className="spark-text-secondary spark-text-12px w-100px m-auto">
                        {children}
                    </p>
                ),
            }}
        />
    </div>
);

ValuePropHeader.propTypes = {
    className: PropTypes.string,
    showTripleValueProp: PropTypes.bool,
};
